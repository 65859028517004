import axios from "axios";

const requestUserData = (baseAddress: string) => {
  const address = (process.env.REACT_APP_DEBUG_ADDRESS || baseAddress).toLowerCase()
  const query = `{
    users(where: {id: "${address.toLowerCase()}"}) {
      id
      aOlympBalance
      bOlympBalance
      amountApprovedChest
      stonesBalance
      approvedChestsMarketplace
      approvedNewChestsMarketplace
      approvedStonesMarketplace
      amountApprovedOlympBridge
      approvedMigratedCharactersMarketplace
      approvedCharactersMigrate
      approvedMigratedCharactersBridge
      approvedNewCharactersMigrate
      amountApprovedFurnace
      olympBalance
      claimingAvailable
      withdrawAmount
      powderBalance
      pendingStones{
        end
        amount
        owner\
      }
      chests {
        id
        rarity
        amount
      }
    }
  }`;
  return runQuery(query);
};

const requestCharacters = (baseAddress: string) => {
  const address = (process.env.REACT_APP_DEBUG_ADDRESS || baseAddress).toLowerCase()
  const query = `{
    users(where: {id: "${address.toLowerCase()}"}) {
      characters {
        id
        name
        nickname
        rarity
        inGame
        migrated
        level
        level_max
        trainingEnd
        trainingTime
      }
    }
  }`;
  return runQuery(query);
};

const requestStones = (baseAddress: string) => {
  const address = (process.env.REACT_APP_DEBUG_ADDRESS || baseAddress).toLowerCase()
  const query = `{
    users(where: {id: "${address.toLowerCase()}"}) {
      pendingStones {
        id
        end
        amount
        alreadyClaim
      }
    }
  }`;
  return runQuery(query);
};

const requestListings = () => {
  const query = `{
    itemListeds (first: 1000) {
      id
      tokenId
      token
      price
      owner
      date
      category
      name
      rarity
      level
      oldContract
    }
  }`;
  return runQuery(query);
};

const requestUserListings = (baseAddress: string) => {
  const address = (process.env.REACT_APP_DEBUG_ADDRESS || baseAddress).toLowerCase()
  const query = `{
    itemListeds(where: {owner: "${address.toLowerCase()}"}) {
      id
      tokenId
      token
      price
      owner
      date
      category
      name
      rarity
      level
      oldContract
    }
  }`;
  return runQuery(query);
}

const runQuery = async (query: string, api?: string) => {
  const request = await axios({
    method: "POST",
    url: api || process.env.REACT_APP_GRAPH_API,
    data: { query: query, variables: null, extensions: { headers: null } },
  });
  return request.data.data;
};

export { requestUserData, requestCharacters, requestStones, requestListings, requestUserListings };
