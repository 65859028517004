import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';

const Profits = () => {
  // end verify password
  // end change password
  const [btnone, setBtnOne] = useState(false);
  const handleBtnOne = () => {
    setBtnOne(!btnone);
  };
  const [btntwo, setBtnTwo] = useState(false);
  const handleBtnTwo = () => {
    setBtnTwo(!btntwo);
  };
  const [btnthree, setBtnThree] = useState(false);
  const handleBtnThree = () => {
    setBtnThree(!btnthree);
  };
  const [btnfour, setBtnFour] = useState(false);
  const handleBtnFour = () => {
    setBtnFour(!btnfour);
  };
  const [btnfive, setBtnFive] = useState(false);
  const handleBtnFive = () => {
    setBtnFive(!btnfive);
  };
  const [btnsix, setBtnSix] = useState(false);
  const handleBtnSix = () => {
    setBtnSix(!btnsix);
  };
  const [btnseven, setBtnSeven] = useState(false);
  const handleBtnSeven = () => {
    setBtnSeven(!btnseven);
  };
  return (
    <div className="profile-calculator" style={{ display: 'none' }}>
      <Row>
        <Col xl={4} lg={4} md={4} className="profile-cal-left-col position-relative">
          <div className="box ">
            <p className="font-32-700 pt-2 pb-2">User</p>
          </div>
        </Col>
        <Col xl={8} lg={8} md={8} className=" ps-xl-4 ps-lg-4 ps-md-4">
          <h1 className="profile-cal-title color-purple">profit calculator</h1>
          <div className="box profile-cal-box text-center pt-4 pb-4">
            <p className="font-20-500">Estimated winrate</p>
            <div className="profile-cal-btn-main mt-3">
              <Button className={btnone ? 'profile-btn-active' : ''} onClick={handleBtnOne}>
                75%
              </Button>
              <Button className={btntwo ? 'profile-btn-active' : ''} onClick={handleBtnTwo}>
                85%
              </Button>
              <Button className={btnthree ? 'profile-btn-active' : ''} onClick={handleBtnThree}>
                90%
              </Button>
            </div>
            <p className="font-20-500 mt-3">Play hours per day</p>
            <div className="profile-cal-btn-main mt-3">
              <Button className={btnfour ? 'profile-btn-active' : ''} onClick={handleBtnFour}>
                1h
              </Button>
              <Button className={btnfive ? 'profile-btn-active' : ''} onClick={handleBtnFive}>
                3h
              </Button>
              <Button className={btnsix ? 'profile-btn-active' : ''} onClick={handleBtnSix}>
                5h
              </Button>
              <Button className={btnseven ? 'profile-btn-active' : ''} onClick={handleBtnSeven}>
                8h
              </Button>
            </div>
            <p className="font-20-700 mt-3">Revenue estimation:</p>
            <h5 className="color-purple profile-cal-price">1,528 $</h5>
            <span className="font-16-500">per month</span>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Profits
