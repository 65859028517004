import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useVoteData } from "src/context/VoteContext";
import { toast } from "sonner";
import axios from "axios";
import Web3Token from "web3-token";
import { formatValue } from "src/utils";
import { useUserData } from "src/context/UserContext";
import { useAccount } from "wagmi";
import { useEthersSigner } from "src/context/ethers";
const Vote = () => {
  const { address } = useAccount();
  const signer = useEthersSigner()
  const { currentChoice, setCurrentChoice, title, description, open, close, snapshot, choices, updateChoice, balance } = useVoteData()
  const [selectedOption, setSelectedOption] = useState<number>(currentChoice);
  const total = choices.reduce((acc, choice) => acc + choice.total, 0);

  const submit = async () => {
    if (balance === 0) {
      toast.error("You are not able to vote because were not holding any tokens at the snapshot time");
      return;
    } else if (selectedOption === -1) {
      toast.error("Please select an option");
      return;
    } else if (selectedOption === currentChoice) {
      toast.error("You have already voted for this option");
      return;
    }
    let signature = ''
    try {
      signature = await Web3Token.sign(msg => signer.signMessage(msg), {
        domain: process.env.REACT_APP_HOSTNAME,
        statement: 'Voting for Olympus',
        expires_in: '3 days'
      });
    } catch (e) {
      toast.error(e.message)
      return
    }

    try {
      await axios.post(`${process.env.REACT_APP_API}/api/vote`, {
        signature,
        address,
        choice: selectedOption,
        vote: 'strategy'
      })
      toast.success('Successfully voted')
      updateChoice(selectedOption, choices[selectedOption].total + balance)
      setCurrentChoice(selectedOption)
    } catch (rawError) {
      const error = rawError as { response?: { data?: { error?: string } } }
      console.log(rawError)
      const errorMessage = error.response?.data?.error || 'An unexpected error has occurred. Please try again'
      toast.error(errorMessage)
    }
  }


  // add class to page
  useEffect(() => {
    document.body.className = "main vote";
    return () => {
      document.body.className = "";
    };
  }, []);
  //   end add class to page

  const beforeVote = new Date(open).getTime() > Date.now()
  const afterVote = new Date(close).getTime() < Date.now()
  const duringVote = !beforeVote && !afterVote
  const olympBalance = useUserData().createdUser.olymp

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="content-top-bar">
          <Row>
            <Col xl={9} lg={9} md={9} className="vote-top-bar-left-col">
              <div className="d-flex">
                <div className="box top-bar-box me-4 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <p className="text-white m-0">
                    <img src="/images/menuicon6-My-Tokens.png" width={"29px"} />
                    <span className="ps-3">{formatValue(olympBalance)} OLYMP in wallet</span>{" "}
                  </p>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <p className='text-white mb-0'><img src='/images/top-bar-forth-icon.png' className='pe-3'></img>1 OLYMP = 1 Vote</p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} className="vote-top-bar-right-col">
              <div className="box top-right-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 py-3 float-end">
                <p className="text-white mb-0 top-right-text d-flex">
                  {" "}
                  {address.slice(0, 2)}...{address.slice(-5)} <img src="/images/circle-icon.png" className="" />
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <h1 className="main-heading color-purple text-center text-lg-start ms-md-5 mt-4" style={{ textTransform: "none" }}>
          Current Vote
        </h1>

        <div className="box position-relative content-box">
          <span className="position-absolute top-timing top-0 end-0 translate-middle-y me-2 me-md-5 text-white">
            {
              beforeVote ? (
                <>Voting opens on {new Date(open).toLocaleString()}</>
              ) : afterVote ? (
                <>Voting closed on {new Date(close).toLocaleString()}</>
              ) : (
                <>Voting closes on {new Date(close).toLocaleString()}</>
              )
            }
            <button className={`btn-${duringVote ? 'success' : 'danger'} ms-3`}>
              {
                duringVote ? 'Open' : 'Closed'
              }
            </button>
          </span>
          <h2 className="vote-inner-title">Vote For The Future Of Olympus Game!</h2>
          <div className="d-flex align-items-start mt-4 position-relative mb-3">
            <div className="box-dark-content">
              Please keep in mind that only Olymp that you were holding on the BNB Chain network at the time of the snapshot are counted for the vote. You had <b>{new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                signDisplay: 'never',
                maximumFractionDigits: 0
              }).format(balance).replace('$', '')}</b> of them at the time of the snapshot.
            </div>
          </div>
          <p className="vote-inner-content" dangerouslySetInnerHTML={{ __html: description }} />
          <span className="inner-hr" />
          {
            choices.map((choice, index) => (
              <div key={index} className="d-flex align-items-start mt-4" onClick={() => setSelectedOption(index)}>
                <span className={`d-inline-block box-dark-num ${selectedOption === index ? 'active-questioning' : ''}`}>{index + 1}</span>
                <div className={`box-dark-content ${selectedOption === index ? 'active-questioning' : ''} ms-3`}>
                  <p className="mb-0">{choice.name}</p>
                  {
                    currentChoice !== -1 && (
                      <p className="mb-0 w-100" style={{ textAlign: 'right', fontWeight: 'bold' }}>{formatValue(choice.total)} OLYMP ({total === 0 ? 0 : formatValue(choice.total / total * 100)}%)</p>
                    )
                  }
                </div>
              </div>
            ))
          }
          <div className="text-center mt-4">
            <button
              className="purple-btn text-white"
              onClick={submit}
              disabled={!duringVote}
            >
              {duringVote ? currentChoice === -1 ? 'Submit Vote' : 'Update Vote' : beforeVote ? 'Voting has not started yet' : 'Voting has ended'}
            </button>
          </div>
        </div>

        {/* <h1 className="main-heading color-purple text-center text-lg-start ms-md-5 mt-4" style={{ textTransform: "none" }}>
          Past Vote
        </h1>

        <div className="box position-relative content-box past-vote">
          <span className="position-absolute top-timing top-0 end-0 translate-middle-y me-2 me-md-5 text-white">
            ended on 12/21/2022, 6:00 PM
            <button className="btn-danger ms-3">Closed</button>
          </span>
          <h2 className="vote-inner-title">Vote For The Future Of Olympus Game!</h2>
          <p className="vote-inner-content">
            We would like to hear your voice about the development strategy you think we should make for the upcoming months.To be able to vote, you must hold OLYMP
            tokens (aOLYMP or bOLYMP). 1 OLYMP=1 vote right.
          </p>
          <span className="inner-hr" />

          <div className="d-flex align-items-start mt-4 position-relative">
            <span className="d-inline-block box-dark-num active-questioning-purple">1</span>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="" className="vote-tooltip">
                  12K OLYMP
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div {...triggerHandler} ref={ref} className="vote-badge past-vote-badge d-inline-block box-dark-middle active-questioning-purple ms-3 px-30">
                  63.92%
                </div>
              )}
            </OverlayTrigger>
            <div className="box-dark-content active-questioning ms-3 ">We just focus on the game development and update the Alpha</div>
          </div>
          <div className="d-flex align-items-start mt-4 position-relative">
            <span className="d-inline-block box-dark-num border-blue">2</span>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="" className="vote-tooltip">
                  12K OLYMP
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div {...triggerHandler} ref={ref} className="vote-badge d-inline-block box-dark-middle border-blue ms-3 px-30 past-vote-badge">
                  6.53%
                </div>
              )}
            </OverlayTrigger>
            <div className="box-dark-content border-blue ms-3">
              We focus on the game development and we organise some tournaments (weekly/monthly) where the Alpha players can win crypto/NFT and physical prizes.
            </div>
          </div>
          <div className="d-flex align-items-start mt-4 position-relative">
            <span className="d-inline-block box-dark-num">3</span>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="" className="vote-tooltip">
                  12K OLYMP
                </Tooltip>
              }
            >
              {({ ref, ...triggerHandler }) => (
                <div {...triggerHandler} ref={ref} className="vote-badge past-vote-badge d-inline-block box-dark-middle ms-3 px-30">
                  29.55%
                </div>
              )}
            </OverlayTrigger>
            <div className="box-dark-content ms-3">
              We work on the mini game, releasing a battle mode where you can use your NFT to fight between players. The players will be able to earn some OLYMP.
            </div>
          </div>
        </div>

        <div className="box position-relative content-box mt-5 third-vote">
          <span className="position-absolute top-timing top-0 end-0 translate-middle-y me-2 me-md-5 text-white">
            ended on 12/21/2022, 6:00 PM
            <button className="btn-danger ms-3">Closed</button>
          </span>
          <h2 className="vote-inner-title">Vote For The Future Of Olympus Game!</h2>
          <p className="vote-inner-content">
            We would like to hear your voice about the development strategy you think we should make for the upcoming months.To be able to vote, you must hold OLYMP
            tokens (aOLYMP or bOLYMP). 1 OLYMP=1 vote right.
          </p>
          <span className="inner-hr" />

          <div className="d-flex align-items-start mt-4 position-relative">
            <span className="d-inline-block box-dark-num active-questioning-purple">1</span>
            <div className="vote-badge d-inline-block box-dark-middle active-questioning-purple ms-3 px-30">63.92%</div>

            <div className="box-dark-content active-questioning ms-3">We just focus on the game development and update the Alpha</div>
          </div>
          <div className="d-flex align-items-start mt-4 position-relative">
            <span className="d-inline-block box-dark-num">2</span>
            <div className="vote-badge d-inline-block box-dark-middle ms-3 px-30">6.53%</div>
            <div className="box-dark-content ms-3">
              We focus on the game development and we organise some tournaments (weekly/monthly) where the Alpha players can win crypto/NFT and physical prizes.
            </div>
          </div>
          <div className="d-flex align-items-start mt-4 position-relative">
            <span className="d-inline-block border-blue box-dark-num">3</span>
            <div className="vote-badge d-inline-block border-blue box-dark-middle ms-3 px-30 third-last-badge">29.55%</div>
            <div className="border-blue box-dark-content ms-3">
              We work on the mini game, releasing a battle mode where you can use your NFT to fight between players. The players will be able to earn some OLYMP.
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Vote;
