import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUserData } from 'src/context/UserContext';
import { formatValue } from 'src/utils';
import { useCharactersData } from 'src/context/CharactersContext';
import { useAccount } from 'wagmi';
const Home = props => {
  const { address } = useAccount()
  useEffect(() => {
    document.body.className = 'main home';
    return () => {
      document.body.className = '';
    };
  }, []);
  const { olymp: olympBalance, inGameBalance, stakingBalance, vestingBalance } = useUserData().createdUser
  const { createdCharacters } = useCharactersData()
  const inGameCharacters = createdCharacters.filter(character => character.inGame)
  const walletsCharacters = createdCharacters.filter(character => !character.inGame)
  const totalCharacters = inGameCharacters.length + walletsCharacters.length

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="content-top-bar">
          <Row className={props.Isopen ? 'blogck' : 'g'}>
            <Col xl={9} lg={9}>
              <div className="d-flex">
                <div className="box top-bar-box me-3 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href="https://whitepaper.olympus.game/characters" target='_blank' rel="noreferrer">
                    <p className="text-white mb-0">
                      <img src="/images/character-icon.png" className="pe-3" /> NFT Characters
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href="https://whitepaper.olympus.game/" target='_blank' rel="noreferrer">
                    <p className="text-white mb-0">
                      <img src="/images/white-paper-icon.png" className="pe-3" /> Whitepaper
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box ms-4 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href={`https://app.1inch.io/#/56/simple/swap/BNB/${process.env.REACT_APP_OLYMP_CONTRACT}`} target='_blank' rel="noreferrer">
                    <p className="text-white mb-0">
                      <img src="/images/pancakeswap.png" className="pe-3" height={25} /> Buy OLYMP
                    </p>
                  </a>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3}>
              <div className="box top-right-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 py-3 float-end">
                <p className="text-white mb-0 top-right-text d-flex">
                  {' '}
                  {address.slice(0, 2)}...{address.slice(-5)} <img src="/images/circle-icon.png" className="" />
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-4">
          <Row>
            <Col xl={8} lg={12} md={12} className="position-relative home-bg-img">
              <div className="purple-gradient-box h-100">
                <div className="home-bg-img">
                  <h1 className="play-game-large-heading mb-0">Play</h1>
                  <p className="play-game-small-heading">olympus game</p>
                  <p className="play-game-sub-text">
                    And earn while having fun!
                  </p>
                  <Link to="/play" className="text-decoration-none">
                    <button className="yellow-btn  text-white  mt-4">Play now</button>
                  </Link>
                </div>
              </div>
              <img src="/images/home-play-img.png" className="home-hamer-img-one position-absolute desktop-display" />
              <img src="/images/home-play-img-mobile.png" className="home-hamer-img-one position-absolute mobile-display" />
              <img src="/images/hamer.png" className="home-hamer-icon-one position-absolute" />
              <img src="/images/hamer.png" className="home-hamer-icon-two position-absolute" />
              <img src="/images/eagle-icon.png" className="home-eagle-icon position-absolute" />
              <img src="/images/axe-icon.png" className="home-axe-icon position-absolute" />
            </Col>
            <Col xl={4} lg={12} md={12} className="play-right-col">
              <img src="/images/play-right-img-one.png" className="w-100 play-right-img1" />
              <img src="/images/play-right-img-two.png" className="w-100 mt-3 play-right-img2" />
            </Col>
          </Row>
        </div>

        <div className="mt-5 mt-xl-4 mt-lg-4 mt-md-4">
          <Row>
            <Col xl={6}>
              <h1 className="main-heading color-yellow ps-0 ps-xl-5 ps-lg-5 ps-md-5">TOKENS</h1>
              <div className="box px-4 py-5 token-box">
                <img src="/images/yellow-line-divider.png" className="w-100 mb-4" />
                <div className="token-text-white d-flex">
                  <p className="">
                    <img src="/images/token-pipe-one.png" className="pe-3" height={'19px'} />
                    Wallet
                  </p>
                  <p className="text-end flex-grow-1">{formatValue(olympBalance)}</p>
                </div>
                <div className="token-text-white d-flex">
                  <p className="">
                    <img src="/images/token-pipe-two.png" className="pe-3" height={'19px'} />
                    In-game
                  </p>
                  <p className="text-end flex-grow-1">{formatValue(inGameBalance)}</p>
                </div>
                <div className="token-text-white d-flex">
                  <p className="">
                    <img src="/images/token-pipe-three.png" className="pe-3" height={'19px'} />
                    Vesting
                  </p>
                  <p className="text-end flex-grow-1">{formatValue(vestingBalance)}</p>
                </div>
                <div className="token-text-white d-flex">
                  <p className="">
                    <img src="/images/token-pipe-four.png" className="pe-3" height={'19px'} />
                    Staking
                  </p>
                  <p className="text-end flex-grow-1">{formatValue(stakingBalance)}</p>
                </div>
                <div className="token-text-white d-flex color-yellow token-last-text">
                  <p className="">
                    <img src="/images/token-pipe-five.png" className="pe-3" height={'19px'} />
                    Total
                  </p>
                  <p className="text-end flex-grow-1">{formatValue(olympBalance + stakingBalance + vestingBalance + inGameBalance)}</p>
                </div>
                <img src="/images/token-icon.png" className="home-token-img" />
              </div>
              <Link to="/token" className="text-decoration-none">
                <button className="yellow-btn  text-white  mt-4 justify-content-center d-flex mx-auto">Manage</button>
              </Link>
            </Col>
            <Col xl={6} className="home-nft-col mt-5 pt-5 mt-xl-0 mt-lg-0 mt-md-0 pt-xl-0 pt-lg-0 pt-md-0">
              <h1 className="main-heading color-purple ps-0 ps-xl-5 ps-lg-5 ps-md-5">NFTs</h1>
              <div className="box px-4 py-5 nft-box">
                <img src="/images/line-divider.png" className="w-100 mb-4" />
                <div className="nft-text-white d-flex">
                  <p className="">
                    <img src="/images/nft-pipe-one.png" className="pe-3" height={'19px'} />
                    Wallet
                  </p>
                  <p className="text-end flex-grow-1">{walletsCharacters.length}</p>
                </div>
                <div className="nft-text-white d-flex">
                  <p className="">
                    <img src="/images/nft-pipe-two.png" className="pe-3" height={'19px'} />
                    In-game
                  </p>
                  <p className="text-end flex-grow-1">{inGameCharacters.length}</p>
                </div>
                <div className="nft-text-white d-flex">
                  <p className="">
                    <img src="/images/nft-pipe-three.png" className="pe-3" height={'19px'} />
                    For sale
                  </p>
                  <p className="text-end flex-grow-1">{0}</p>
                </div>

                <div className="nft-text-white d-flex pt-0 pt-xl-5 pt-lg-5 pt-md-0 color-purple nft-last-text">
                  <p className="">
                    <img src="/images/nft-pipe-four.png" className="pe-3" height={'19px'} />
                    Total
                  </p>
                  <p className="text-end flex-grow-1">{totalCharacters}</p>
                </div>
                <img src="/images/nft-bg.png" className="home-nft-img" />
              </div>
              <Link to="/nftwallet" className="text-decoration-none">
                <button className="purple-btn  text-white  mt-4 justify-content-center d-flex mx-auto">Manage</button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Home;
