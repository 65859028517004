import { noop, sortBy } from "lodash";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { ESortOption, priceOptions, chestType } from "src/constants";
import { IMarketItem, IOption } from "src/interfaces";
import { requestListings, requestUserListings } from "src/queries";
import { CharactersContext } from "./CharactersContext";
import { useUserData } from "./UserContext";
import { collection, formatChest, formatStone } from "src/data";
import { useAccount } from "wagmi";

interface IMarketplaceContextProps {
  marketItems: IMarketItem[]
  setMarketItems: React.Dispatch<React.SetStateAction<IMarketItem[]>>
  myCollection: IMarketItem[]
  setMyCollection: React.Dispatch<React.SetStateAction<IMarketItem[]>>
  searchTerm: string
  fetchListings: () => void
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  sortOption: IOption
  setSortOption: React.Dispatch<React.SetStateAction<IOption>>
  getCollectionById: (id: string) => IMarketItem
  handleSetSearchTerm: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const useMarketplaceContext = () => {
  const context = useContext(MarketplaceContext)

  if (!context) {
    throw new Error('Missing marketplace context')
  }

  return context
}

export const MarketplaceContext = createContext<IMarketplaceContextProps>({
  marketItems: [],
  setMarketItems: noop,
  myCollection: [],
  setMyCollection: noop,
  searchTerm: '',
  setSearchTerm: noop,
  fetchListings: noop,
  sortOption: priceOptions[0],
  setSortOption: noop,
  getCollectionById: () => null,
  handleSetSearchTerm: noop
})

export const MarketplaceContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [marketItems, setMarketItems] = useState<IMarketItem[]>([])
  const [myCollection, setMyCollection] = useState<IMarketItem[]>(collection)
  const [searchTerm, setSearchTerm] = useState('')
  const [sortOption, setSortOption] = useState<IOption>(priceOptions[0])
  const { address } = useAccount()

  const { chests: heldChests, createdUser } = useUserData()
  const chests = useMemo(() => heldChests.reverse().map(chest => chestType.find(chestType => chestType.id === chest)), [heldChests])

  const { createdCharacters } = useContext(CharactersContext)

  const location = useLocation()

  const allCollectionItems = useMemo(() => !address ? [] : [
    ...marketItems.filter(item => item.owner === address.toLowerCase()),
    ...chests.map((chest, index) => formatChest(chest, address, index)),
    ...createdCharacters.map(character => ({ ...character, id: `char_${character.id}`, tokenId: character.id, name: character.type, date: 0, owner: address })),
    ...[...Array(createdUser.stones)].map((v, i) => formatStone(i + '', address))
  ] as IMarketItem[],
  [chests, createdCharacters, createdUser.stones, address, marketItems])

  useEffect(() => {
    setMarketItems(marketItems => {
      switch (sortOption.value) {
        case ESortOption.HIGHEST_PRICE:
          return sortBy(marketItems, (x) => -x.price)

        case ESortOption.LOWEST_PRICE:
          return sortBy(marketItems, (x) => x.price)

        default:
          return marketItems
      }
    })
  }, [setMarketItems, sortOption])

  const getCollectionById = useCallback((id: string) => {
    return myCollection.find(x => x.id === id)
  }, [myCollection])

  const handleSetSearchTerm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }, [setSearchTerm])

  useEffect(() => {
    setMyCollection(allCollectionItems)
    setSearchTerm('')
  }, [location, setSearchTerm, allCollectionItems])

  const fetchListings = async () => {
    if (!address) return
    const userListings = await requestUserListings(address.toLowerCase())
    const listingsRaw = await requestListings()
    const userListingsIds = userListings.itemListeds.map(item => item.id)
    const uniqueListings = [...listingsRaw.itemListeds.filter(item => !userListingsIds.includes(item.id)), ...userListings.itemListeds]
    const listings = uniqueListings.map(item => ({
      id: item.id,
      collection: 1,
      name: item.name,
      price: parseFloat(process.env.REACT_APP_MAIN_CHAIN_ID === '5' ? item.price : item.price)  / 1e18,
      tokenId: item.tokenId,
      owner: item.owner,
      date: item.date,
      oldContract: item.oldContract,
      rarity: item.rarity,
      category: item.category,
      level: item.level === '0' ? null : item.level,
      type: "sale",
      pastAuction: false
    })) as IMarketItem[]
    setMarketItems(listings)
  }

  return (
    <MarketplaceContext.Provider
      value={{
        marketItems,
        setMarketItems,
        myCollection,
        setMyCollection,
        fetchListings,
        searchTerm,
        setSearchTerm,
        sortOption,
        setSortOption,
        getCollectionById,
        handleSetSearchTerm
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  )
}