import React, { useEffect } from "react";
import { useUserData } from "src/context/UserContext";
import { useDisconnect } from "wagmi";
const Disconnect = () => {
  const { disconnect } = useDisconnect();
  const { setSignature} = useUserData()

  useEffect(() => {
    setSignature('')
    disconnect();
    window.location.pathname = "/"
  }, [disconnect, setSignature]);

  return (
    <>
    </>
  );
};
export default Disconnect;
