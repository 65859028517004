import React, { useCallback } from 'react';

import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { useUserData } from 'src/context/UserContext';
import { toast } from 'sonner';
import axios from 'axios';
import { useAccount } from 'wagmi';
const Register = () => {
  const { createdUser, setCreatedUser, signature } = useUserData()
  const [registering, setRegistering] = useState(false)
  const { address } = useAccount()

  // add class to page
  useEffect(() => {
    document.body.className = 'main play';
    return () => {
      document.body.className = '';
    };
  }, []);
  //   end add class to page
  const [passwordType, setPasswordType] = useState('password');
  const [passwordInput, setPasswordInput] = useState('');
  const handlePasswordChange = evnt => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const [username, setUsername] = useState('')
  // verify password
  const [verifypasswordType, setVerifyPasswordType] = useState('password');
  const [verifypasswordInput, setVerifyPasswordInput] = useState('');
  const handleVerifyPasswordChange = evnt => {
    setVerifyPasswordInput(evnt.target.value);
  };
  const toggleVerifyPassword = () => {
    if (verifypasswordType === 'password') {
      setVerifyPasswordType('text');
      return;
    }
    setVerifyPasswordType('password');
  };

  const [email, setEmail] = useState('');
  const [emailerror, setEmailError] = useState(null);
  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleChange = event => {
    if (!isValidEmail(event.target.value)) {
      setEmailError('Invalid email');
    } else {
      setEmailError(null);
    }
    setEmail(event.target.value);
  };


  const createAccount = useCallback(async () => {
    if (passwordInput !== verifypasswordInput) {
      toast.error("Passwords do not match")
      return
    } else if (!isValidEmail(email)) {
      toast.error("Invalid email")
      return
    } else if (username.length < 3) {
      toast.error("Username must be at least 3 characters")
      return
    } else if (passwordInput.length < 8) {
      toast.error("Password must be at least 8 characters")
      return
    }
    setRegistering(true)
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/register`,
        {
          email,
          username,
          password: passwordInput,
          signature,
          address
        }
      )
      setTimeout(() => {
        setCreatedUser({
          ...createdUser,
          registered: true,
          logged: false
        })
      }, 3000)
      toast.success("Account successfully created")
    } catch (rawError) {
      const errorMessage = "An unexpected error has occurred while registering your account. Please contact us on Discord if the issue keeps occurring.";
      const error = rawError as { response?: { data?: { error?: string } } }
      console.log(rawError)
      toast.error(error.response?.data?.error || errorMessage)
    }
    setRegistering(false)
  }, [setCreatedUser, email, createdUser, username, passwordInput, verifypasswordInput, signature, address])

  return (
    <>
      <div className="create-account" style={{ display: createdUser.paid && !createdUser.registered ? 'block' : 'none' }} id="register">
        <div className="create-account-inner ">
          <h1 className="text-center main-heading color-purple heading-font-60">Create your account</h1>
          <div className="box ">
            <Form className="craete-account-form p-4 p-xl-5 p-lg-5 p-md-5">
              <Form.Group className="form-input-group" controlId="formBasicName">
                <Form.Label className="label">Username</Form.Label>
                <Form.Control type="text" placeholder="" className="input" value={username} onChange={(e) => setUsername(e.target.value)} />
              </Form.Group>

              <Form.Group className="form-input-group" controlId="formBasicEmail">
                <Form.Label className="label">E-mail</Form.Label>
                <Form.Control type="email" placeholder="" className={emailerror ? 'input inputerror' : 'input'} value={email} onChange={handleChange} />
              </Form.Group>
              <div className="form-input-group valiadtion-error">
                <div />
                {emailerror && <p className="error mb-0">{emailerror}</p>}
              </div>
              <Form.Group className="form-input-group position-relative" controlId="formBasicPassword">
                <Form.Label className="label">Password</Form.Label>
                <Form.Control type={passwordType} value={passwordInput} onChange={handlePasswordChange} placeholder="" className="input" />
                <div className="position-absolute password-eye-icon" onClick={togglePassword}>
                  {passwordType === 'password' ? <img src="/images/eye-slash-Filled.svg" /> : <img src="/images/open-eye.png" />}
                </div>
              </Form.Group>

              <Form.Group className="form-input-group position-relative" controlId="formBasicVerifyPassword">
                <Form.Label className="label">Verify</Form.Label>
                <Form.Control type={verifypasswordType} value={verifypasswordInput} onChange={handleVerifyPasswordChange} placeholder="" className="input" />
                <div className="position-absolute password-eye-icon" onClick={toggleVerifyPassword}>
                  {verifypasswordType === 'password' ? <img src="/images/eye-slash-Filled.svg" /> : <img src="/images/open-eye.png" />}
                </div>
              </Form.Group>
            </Form>
          </div>
          <Button className="purple-btn mt-5 text-center justify-content-center d-flex mx-auto" type="button" disabled={registering} onClick={createAccount}>
            {registering ? "Creating account..." : "Create account"}
          </Button>
        </div>
      </div>
    </>
  );
};
export default Register;
