import React, { useCallback, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { useEffect } from 'react';
import Sortby from './Sortby';
import { useCharactersData } from 'src/context/CharactersContext';
import { getCardBackground, getCharacterAvatar } from 'src/utils';
import { ICharacter } from 'src/interfaces';
import { useUserData } from 'src/context/UserContext';
import { toast } from 'sonner';
import { ethers } from 'ethers';
import { Characters, CharactersBridge } from 'src/abis';
import { getParsedEthersError } from '@enzoferey/ethers-error-parser';
import MigrateCharacter from './MigrateCharacter';
import axios from 'axios';
import { useAccount } from 'wagmi';
import { useEthersSigner } from 'src/context/ethers';
import { Link } from 'react-router-dom';
const Nftwallet = () => {
  const { createdCharacters, handleUpdateCharacter } = useCharactersData()
  const { createdUser, setCreatedUser, signature } = useUserData()
  const [approving, setApproving] = useState('')
  const [bridging, setBridging] = useState('')
  const { address } = useAccount()
  const signer = useEthersSigner()

  const [tab, setTab] = useState<'first' | 'second'>('first');
  const walletsNfts = createdCharacters.filter((character) => character.inGame === false)
  const inGameNfts = createdCharacters.filter((character) => character.inGame === true)
  useEffect(() => {
    document.body.className = 'main my-wallet';
    return () => {
      document.body.className = '';
    };
  }, []);

  const sendInGame = useCallback(async (character: ICharacter) => {
    if (!createdUser.registered) {
      toast.error('You need to register first. Please on go the "play" page to proceed.')
      return
    }
    const approved = createdUser.approvedMigratedCharactersBridge
    if (!approved) {
      setApproving(character.id)
      const contractAddress = process.env.REACT_APP_MIGRATED_CHARACTERS_CONTRACT
      const contract = new ethers.Contract(contractAddress as string, Characters, signer)
      try {
        await (await contract.setApprovalForAll(process.env.REACT_APP_CHARACTERS_BRIDGE_CONTRACT as string, true)).wait()
        toast.success('Successfully approved. You can now send your character in game.')
        setCreatedUser(createdUser => ({
          ...createdUser,
          approvedMigratedCharactersBridge: true
        }))
      } catch (rawError) {
        const error = getParsedEthersError(rawError as Error);
        console.log(rawError);
        let errorMessage = "An unexpected error has occurred while approving. Please try again";
        errorMessage = error && error.context ? error.context : errorMessage;
        toast.error(errorMessage);
      }
      setApproving('')
      return
    }

    let tx = ''
    const contract = new ethers.Contract(process.env.REACT_APP_CHARACTERS_BRIDGE_CONTRACT as string, CharactersBridge, signer)
    try {
      setBridging(character.id)
      console.log(character)
      const rawTx = await (await contract.sendInGame(character.id.replace('3-', ''), address)).wait()
      tx = rawTx.transactionHash
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while sending character in game. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
      setBridging('')
      return
    }

    try {
      await axios.post(process.env.REACT_APP_API + '/api/sendingame', {
        transaction: tx,
        signature,
        address
      })
      toast.success('Character successfully sent in game.')
      handleUpdateCharacter(character.id, 'inGame', true)
    } catch (rawError) {
      const errorMessage = 'An unexpected error has occurred while sending character in game.'
      const error = rawError as { response?: { data?: { error?: string } } }
      console.log(rawError)
      toast.error((error.response?.data?.error || errorMessage) + '. Please contact us on Discord.')
    }
    setBridging('')
  }, [createdUser, signer, setCreatedUser, handleUpdateCharacter, address, signature])

  const sendInWallet = useCallback(async (character: ICharacter) => {
    if (!createdUser.registered) {
      toast.error('You need to register first. Please on go the "play" page to proceed.')
      return
    }
    const contract = new ethers.Contract(process.env.REACT_APP_CHARACTERS_BRIDGE_CONTRACT as string, CharactersBridge, signer)
    setBridging(character.id)
    let tx = ''
    try {
      const rawTx = await (await contract.sendFromGame(character.id.replace('3-', ''))).wait()
      tx = rawTx.transactionHash
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while sending character in wallet";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage + '. Please contact us on Discord.');
      setBridging('')
      return
    }
    try {
      await axios.post(process.env.REACT_APP_API + '/api/sendfromgame', {
        transaction: tx,
        signature,
        address
      })
      toast.success('Character successfully sent in wallet.')
      handleUpdateCharacter(character.id, 'inGame', false)
    } catch (rawError) {
      const errorMessage = 'An unexpected error has occurred while sending character in wallet.'
      const error = rawError as { response?: { data?: { error?: string } } }
      console.log(rawError)
      toast.error((error.response?.data?.error || errorMessage) + '. Please contact us on Discord.')
    }
    setBridging('')
  }, [handleUpdateCharacter, signer, address, signature, createdUser.registered])

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="">
          <div className="content-top-bar">
            <Row>
              <Col xl={9} lg={9}>
                <div className="d-flex">
                  <div className="box top-bar-box me-4 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                    <a href="https://whitepaper.olympus.game/characters" target='_blank' rel="noreferrer">
                      <p className="text-white m-0">
                        <img src="/images/character-icon.png" className="pe-3" /> NFT Characters
                      </p>
                    </a>
                  </div>
                  <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                    <a href="https://whitepaper.olympus.game/" target='_blank' rel="noreferrer">
                      <p className="text-white mb-0">
                        <img src="/images/white-paper-icon.png" className="pe-3" /> Whitepaper
                      </p>
                    </a>
                  </div>
                  <div className="box top-bar-box ms-4 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                    <a href={`https://app.1inch.io/#/56/simple/swap/BNB/${process.env.REACT_APP_OLYMP_CONTRACT}`} target='_blank' rel="noreferrer">
                      <p className="text-white mb-0">
                        <img src="/images/pancakeswap.png" className="pe-3" height={25} /> Buy OLYMP
                      </p>
                    </a>
                  </div>
                </div>
              </Col>
              <Col xl={3} lg={3}>
                <div className="box top-right-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 py-3 float-end">
                  <p className="text-white mb-0 top-right-text d-flex">
                    {' '}
                    {address.slice(0, 2)}...{address.slice(-5)} <img src="/images/circle-icon.png" className="" />
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <Tab.Container defaultActiveKey="first" onSelect={(key: 'first' | 'second') => setTab(key)}>
            <Row className="mt-4 mt-xl-5 mt-lg-5 mt-md-5">
              <Col xl={7} lg={7} md={7} xs={12}>
                <Nav variant="pills" className="tabs-head">
                  <Nav.Item>
                    <Nav.Link eventKey="first" className="tab-title">
                      Wallet NFTs
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second" className="tab-title">
                      In-game NFTs
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col xl={5} lg={5} md={5} xs={12}>
                <div className="search-bar-conatiner  ps-0 ps-xl-4 ps-lg-4 ps-md-4 mt-4 mt-xl-0 mt-lg-0 mt-md-0">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Search" className="seach-bar" />
                  </Form.Group>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xl={7} lg={6} md={6} className="d-none d-xl-block d-lg-block d-md-block nft-wallet-main-title">
                <h1 className="main-heading color-purple text-capitalize main-heading-space-left">{tab === 'first' ? walletsNfts.length : inGameNfts.length} items</h1>
              </Col>

              <Col xl={5} lg={6} md={6} className="position-relative  sort-col mt-4 mt-xl-auto mt-lg-auto mt-md-auto nft-wallet-sort-main text-end my-auto">
                <Sortby />
              </Col>
            </Row>

            <div className="box margin-top-box p-3  p-xl-4  pt-xl-3 nft-tab-content">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="nft-first-tab-content">
                    <Row className="">
                      {
                        walletsNfts.map((character) => (
                          <Col xl={3} lg={4} md={6} xs={12} className="mt-5 mt-xl-3 mt-lg-3 mt-md-3  nft-card-main" key={character.id}>
                            <div className="nft-card">
                              <div className="nft-inner-box">
                                <img src={getCharacterAvatar(character.type)} className={`img-fluid w-100 ${getCardBackground(character.rarity)}`} />
                                <div className="nft-text-area d-flex pt-3 pb-3 ">
                                  <div className="ps-3 purple-gradient-line position-relative">
                                    <h3 className="nft-title text-white">{character.name} {character.id}</h3>
                                    <p className="nft-text text-white mb-0">{character.rarity} Rarity</p>
                                    <p className="nft-level text-white mb-0">Level {character.level}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="nft-button-area px-3 py-2 mt-3 mt-xl-0 mt-lg-0 mt-md-0">
                                {
                                  character.migrated ? (
                                    <>
                                      <Link to="/marketplace/user">
                                        <button className="purple-btn w-100">Sell</button>
                                      </Link>
                                      <button
                                        className="green-btn mt-0 mt-xl-3 mt-lg-3 mt-md-3 w-100"
                                        onClick={() => sendInGame(character)}
                                        disabled={bridging !== '' || approving !== ''}
                                      >
                                        {approving === character.id ? 'Approving...' : bridging === character.id ? 'Sending...' : 'Send in Game'}
                                      </button>
                                    </>
                                  ) : (
                                    <MigrateCharacter character={character} />
                                  )
                                }

                              </div>
                            </div>
                          </Col>
                        ))
                      }
                    </Row>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  <div className="">
                    <Row className="">
                      {
                        inGameNfts.map((character) => (
                          <Col xl={3} lg={4} md={6} xs={12} className="mt-5 mt-xl-3 mt-lg-3 mt-md-3  nft-card-main">
                            <div className="nft-card">
                              <div className="nft-inner-box">
                                <img src={getCharacterAvatar(character.type)} className={`img-fluid w-100 ${getCardBackground(character.rarity)}`} />
                                <div className="nft-text-area d-flex pt-3 pb-3 ">
                                  <div className="ps-3 purple-gradient-line position-relative">
                                    <h3 className="nft-title text-white">{character.name}</h3>
                                    <p className="nft-text text-white mb-0">{character.rarity} Rarity</p>
                                    <p className="nft-level text-white mb-0">Level {character.level}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="nft-button-area px-3 py-2">
                                <button
                                  className="purple-btn"
                                  onClick={() => sendInWallet(character)}
                                  disabled={bridging !== '' || approving !== ''}
                                >
                                  {bridging === character.id ? 'Sending...' : 'Send in Wallet'}
                                </button>
                              </div>
                            </div>
                          </Col>
                        ))
                      }
                    </Row>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
export default Nftwallet;
