import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useUserData } from 'src/context/UserContext';
import { formatValue } from 'src/utils';
import { useAccount } from 'wagmi';
const Staking = () => {
  const { address } = useAccount()
  const products = [
    {
      pool: (
        <p className="mb-0">
          <img src="/images/token-pipe-two.png" className="pe-2" alt="" />5% APY - 3 Months
        </p>
      ),
      staked: '152 012,25',
      sdate: '11/04/2023 16:01',
      cdate: '11/07/2023 16:01',
      status: (
        <button type="button" className="green-btn btn btn-primary">
          Claim
        </button>
      )
    },
    {
      pool: (
        <p className="mb-0">
          <img src="/images/nft-pipe-two.png" className="pe-2" alt="" />12% APY - 6 Months
        </p>
      ),
      staked: '516',
      sdate: '11/06/2023 16:01',
      cdate: '11/08/2023 16:01',
      status: <p className="locked mb-0">Locked</p>
    },
    {
      pool: (
        <p className="mb-0">
          <img src="/images/nft-pipe-one.png" className="pe-2" alt="" />30% APY - 1 Year
        </p>
      ),
      staked: '260 001,20',
      sdate: '11/07/2023 16:01',
      cdate: '11/09/2023 16:01',
      status: <p className="claimed mb-0"> Claimed</p>
    }
  ];
  const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (sortOrder === 'asc' ? 'sorting-asc' : 'sorting-desc');
  const columns = [
    { dataField: 'pool', text: 'Yield and stake pool' },
    { dataField: 'staked', text: 'OLYMP staked' },
    { dataField: 'sdate', text: 'Stake date ', sort: true, headerSortingClasses },
    { dataField: 'cdate', text: 'Claim date', sort: true, headerSortingClasses },
    { dataField: 'status', text: 'Status' }
  ];
  const defaultSorted = [
    {
      dataField: 'sdate',
      order: 'desc'
    }
  ];
  // add class to page
  useEffect(() => {
    document.body.className = 'main staking';
    return () => {
      document.body.className = '';
    };
  }, []);
  //   end add class to page
  const olympBalance = useUserData().createdUser.olymp

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="content-top-bar">
          <Row>
            <Col xl={9} lg={9} className="staking-topbar-left-col">
              <div className="d-flex">
                <div className="box top-bar-box me-0 me-xl-4 me-lg-4 me-md-0  px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <p className="text-white m-0">
                    <img src="/images/top-bar-third-icon.png" className="pe-3" />{formatValue(olympBalance)} OLYMP in wallet
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} className="staking-topbar-right-col">
              <div className="box top-right-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 py-3 float-end">
                <p className="text-white mb-0 top-right-text d-flex">
                  {' '}
                  {address.slice(0, 2)}...{address.slice(-5)} <img src="/images/circle-icon.png" className="" />
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col xl={4} lg={6} md={6} xs={12} className="staking-col">
              <div className="staking-card-one">
                <div className="box  text-center px-4 py-4">
                  <h3 className="main-heading color-yellow font-32">3 months</h3>
                  <h4 className="main-heading color-yellow font-60">+5%</h4>
                  <h5 className="main-heading text-white font-30">1 512 OLYMP</h5>
                  <p className="font-16-500 mb-0">in staking</p>
                  <img src="/images/yellow-line-divider.png" className="w-100" height={'4px'} />
                  <p className="font-24-700 text-white mb-1">Stake more OLYMP</p>
                  <p className="font-16-500 mb-0">Type in the amount you want to stake for 3 months:</p>

                  <input type="text" placeholder="10 000" className=" py-2 staking-text-box  border-0 w-100 text-center py-1" />

                  <p className="font-16-500 text-white mt-3 mb-0">Your OLYMP after 3 months</p>
                  <div className="box py-2 staking-text-box">
                    <p className="font-20-700 text-center mb-0 color-yellow py-1">10 500</p>
                  </div>
                  <Button className="yellow-btn mt-4">Stake OLYMP</Button>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="staking-col">
              <div className="staking-card-two">
                <div className="box  text-center px-4 py-4">
                  <h3 className="main-heading color-green font-32">6 months</h3>
                  <h4 className="main-heading color-green font-60">+13%</h4>
                  <h5 className="main-heading text-white font-30">516 OLYMP</h5>
                  <p className="font-16-500 mb-0">in staking</p>
                  <img src="/images/green-line-divider.png" className="w-100" height={'4px'} />
                  <p className="font-24-700 text-white mb-1">Stake more OLYMP</p>
                  <p className="font-16-500 mb-0">Type in the amount you want to stake for 3 months:</p>

                  <input type="text" placeholder="10 000" className=" py-2 staking-text-box  border-0 w-100 text-center py-1" />
                  <p className="font-16-500 text-white mt-3 mb-0">Your OLYMP after 3 months</p>
                  <div className="box py-2 staking-text-box">
                    <p className="font-20-700 text-center mb-0 color-green py-1">10 500</p>
                  </div>
                  <Button className="green-btn mt-4">Stake OLYMP</Button>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={6} md={6} xs={12} className="staking-col">
              <div className="staking-card-three">
                <div className="box  text-center px-4 py-4">
                  <h3 className="main-heading color-purple font-32">1 YEAR</h3>
                  <h4 className="main-heading color-purple font-60">+30%</h4>
                  <h5 className="main-heading text-white font-30">260 001,20 OLYMP</h5>
                  <p className="font-16-500 mb-0">in staking</p>
                  <img src="/images/stak-purple-line.png" className="w-100" height={'4px'} />
                  <p className="font-24-700 text-white mb-1">Stake more OLYMP</p>
                  <p className="font-16-500 mb-0">Type in the amount you want to stake for 3 months:</p>

                  <input type="text" placeholder="10 000" className=" py-2 staking-text-box  border-0 w-100 text-center py-1" />
                  <p className="font-16-500 text-white mt-3 mb-0">Your OLYMP after 3 months</p>
                  <div className="box py-2 staking-text-box">
                    <p className="font-20-700 text-center mb-0 color-purple py-1">10 500</p>
                  </div>
                  <Button className="purple-btn mt-4">Stake OLYMP</Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mobile-display">
          <h1 className="main-heading color-purple text-center" style={{ marginTop: '39px', paddingBottom: '10px' }}>
            transactions
          </h1>
          <Row>
            <Col xl={6} lg={6} md={6} xs={12}>
              <div className="box d-flex pt-3 pe-3 pb-3 staking-mobile-first-box">
                <img src="/images/token-icon.png" width={'68px'} height={'77px'} style={{ objectFit: 'cover', marginLeft: '-10px' }} />
                <div className="ps-3">
                  <p className="font-16-700 text-white mb-0 transation-yellow-line">
                    <span className="ps-2">5% APY - 3 months staking</span>
                  </p>
                  <p className="font-16-700 text-white mb-0 mt-2 transation-green-line">
                    <span className="ps-2">12% APY - 6 months staking</span>
                  </p>
                  <p className="font-16-700 text-white mb-0 mt-2 transation-purple-line">
                    <span className="ps-2">30% APY - 1 year staking</span>
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} xs={12}>
              <div className="box p-4 mt-4 mt-xl-0 mt-md-0 transation-yellow-line-box">
                <p className="font-16-700 text-white m-0 p-0">152 012,25 OLYMP staked</p>
                <div className="d-flex mt-3">
                  <div className="text-white">
                    <p className="font-12-500 mb-1">
                      <img src="/images/arrow-right-Filled.svg" className="pe-2" />11/04/2023 16:01
                    </p>
                    <p className="font-12-500 mb-0">
                      <img src="/images/arrow-left-Filled.svg" className="pe-2" />11/04/2023 16:01
                    </p>
                  </div>
                  <div className="text-end flex-grow-1 mt-auto">
                    <Button className="green-btn">Claim</Button>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} xs={12}>
              <div className="box p-4 mt-4 transation-green-line-box">
                <p className="font-16-700 text-white m-0 p-0">516 OLYMP staked</p>
                <div className="d-flex mt-3">
                  <div className="text-white">
                    <p className="font-12-500 mb-1">
                      <img src="/images/arrow-right-Filled.svg" className="pe-2" />11/04/2023 16:01
                    </p>
                    <p className="font-12-500 mb-0">
                      <img src="/images/arrow-left-Filled.svg" className="pe-2" />11/04/2023 16:01
                    </p>
                  </div>
                  <div className="text-end flex-grow-1 mt-auto">
                    <p className="locked mb-0">
                      LOCKED <img src="/images/lock-line.png" className="ps-2" />
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} md={6} xs={12}>
              <div className="box p-4 mt-4  transation-purple-line-box">
                <p className="font-16-700 text-white m-0 p-0">516 OLYMP staked</p>
                <div className="d-flex mt-3">
                  <div className="text-white">
                    <p className="font-12-500 mb-1">
                      <img src="/images/arrow-right-Filled.svg" className="pe-2" />11/04/2023 16:01
                    </p>
                    <p className="font-12-500 mb-0">
                      <img src="/images/arrow-left-Filled.svg" className="pe-2" />11/04/2023 16:01
                    </p>
                  </div>
                  <div className="text-end flex-grow-1 mt-auto">
                    <p className="claimed mb-0">
                      CLAIMED <img src="/images/claim-line.png" className="ps-2" />
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="staking-table desktop-display">
          <BootstrapTable keyField="date" data={products} columns={columns} />
        </div>
      </div>
    </>
  );
};
export default Staking;
