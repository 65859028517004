import React, { useEffect, useLayoutEffect, useState } from "react";

import ReactSlider from "react-slider";

interface Props {
  onChange: (value: number) => void;
  values: number[];
  min: number;
  max: number;
  step: number;
}
const Doubleslider: React.FC<Props> = ({ onChange, values, min, max, step}) => {
  return (
    <>
      <div className="App">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          onChange={onChange}
          defaultValue={values}
          min={min}
          value={values}
          max={max}
          ariaLabelledby={["first-slider-label", "second-slider-label"]}
          ariaValuetext={state => `Thumb value ${state.valueNow}`}
          renderThumb={(props, state) => (
            <div {...props}>{state.valueNow}</div>
          )}
          pearling
        />
      </div>
    </>
  );
};
export default Doubleslider;
