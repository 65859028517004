import { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup } from 'react-bootstrap'
import PurchaseOlympModal from './PurchaseOlympForm'
import { toast } from 'sonner'
import axios from 'axios'
import { ethers } from 'ethers'
import { Contract as MulticallContract, Provider } from "ethers-multicall";
import { Pair } from 'src/abis'

const PurchaseOlymp = () => {
  const [price, setPrice] = useState(-1)
  const [olympAmount, setOlympAmount] = useState(0)
  const [showModal, setModalShown] = useState(false)

  const handleChange = (value: string) => {
    if (value.match(/\./g)) {
      const [, decimal] = value.split('.')
      if (decimal?.length > 2) {
        return
      }
    }
    setOlympAmount(parseFloat(value))
  }

  const fetchOlympPrice = async () => {
    const olympBnbAddress = '0xb358e43d0fa4802ce5f5406b12ba9122fadbd171'
    const bnbUsdAddress = '0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae'
    const eurUsdAddress = '0x1ae3b4cee159c2a75190d2f89d7fca249c5dad03'

    const provider = await new ethers.providers.JsonRpcProvider(process.env.REACT_APP_MAIN_CHAIN_RPC);
    const ethcallProvider = new Provider(provider);
    await ethcallProvider.init()

    const olympBnbPair = new MulticallContract(olympBnbAddress, Pair)
    const bnbUsdPair = new MulticallContract(bnbUsdAddress, Pair)
    const calls = [
      olympBnbPair.getReserves(),
      bnbUsdPair.getReserves()
    ]
    const response = await ethcallProvider.all(calls)
    const olympPrice = parseFloat(ethers.utils.formatEther(response[0][1])) / parseFloat(ethers.utils.formatEther(response[0][0]))
    const bnbPrice = parseFloat(ethers.utils.formatEther(response[1][0])) / parseFloat(ethers.utils.formatEther(response[1][1]))

    const eurReq = await axios.get(`https://api.dexscreener.com/latest/dex/pairs/ethereum/${eurUsdAddress}`)
    const eurInUsd = eurReq.data.pairs[0].priceUsd

    setPrice(olympPrice * bnbPrice / eurInUsd)
  }

  useEffect(() => {
    fetchOlympPrice()
  }, [])

  const handlePurchase = () => {
    if (isNaN(olympAmount) || olympAmount === 0) {
      toast.error('Please enter a valid amount')
      return
    }
    setModalShown(true)
  }

  return (
    <Col xl={6}>
      <div className="mt-5 mt-xl-0">
        <h1 className="main-heading color-purple text-capitalize font-60 text-start ps-0 ps-xl-5 ps-lg-5 ps-md-5">Buy OLYMP</h1>
        <div className="box p-5 token-tab-content-box">
          <p className="font-20-500 text-white mb-1">Purchase OLYMP by bank card and have it sent to your game account.</p>
          <img src="/images/line-divider.png" className="w-100" />

          {
            price === -1 ? (
              <div style={{ height: '250px' }}>
                <p className="font-24-700 text-white mt-4">Fetching Olymp Price...</p>
                <div className="loader">
                  <div style={{ marginLeft: '100px' }}>
                    <svg className="spinner" width={50} height={50} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="path" fill="none" strokeWidth={6} strokeLinecap="round" cx={33} cy={33} r={30} />
                    </svg>
                  </div>
                </div>
              </div>
            ) : showModal ? (
              <PurchaseOlympModal show={showModal} close={() => setModalShown(false)} eurAmount={olympAmount * price} />
            ) : (
              <>
                <div className="token-width-291" style={{ width: '291px' }}>
                  <p className="font-24-700 text-white mt-4">Purchase OLYMP</p>
                  <Form className="token-box-form">
                    <InputGroup className="mb-3">
                      <InputGroup.Text className='input'>$OLYMP</InputGroup.Text>
                      <Form.Control
                        value={olympAmount}
                        step={0.01}
                        type="number"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder="Amount in OLYMP"
                        className='input'
                      />
                    </InputGroup>
                    <InputGroup className="mt-3">
                      <InputGroup.Text className='input'>$EUR</InputGroup.Text>
                      <Form.Control
                        value={isNaN(olympAmount) ? '' : (olympAmount * price).toFixed(2)}
                        disabled
                        placeholder="Amount in EUR"
                        className='input'
                      />
                    </InputGroup>
                  </Form>
                  <p className="font-20-500 text-white mt-2" style={{ fontSize: '12px', lineHeight: '15px' }}>The received amount may slightly vary if the price changes between purchase and delivery.</p>
                  <Button className="purple-btn mt-2 token-btn" onClick={handlePurchase}>Purchase</Button>
                </div>
              </>
            )
          }
        </div>
      </div>
    </Col>
  )
}

export default PurchaseOlymp
