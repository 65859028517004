import { noop } from "lodash";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { requestCharacters } from "src/queries";
import { CharacterNames, ICharacter } from "../interfaces";
import { createCharactersObject, getCharacterAvatar } from "../utils";

interface ICharacterContextProps {
  createdCharacters: ICharacter[]
  setCreatedCharacters: React.Dispatch<React.SetStateAction<ICharacter[]>>
  selectedCharacter: ICharacter
  setSelectedCharacter: React.Dispatch<React.SetStateAction<ICharacter>>
  stonesToNextLevel: number
  setStonesToNextLevel: React.Dispatch<React.SetStateAction<number>>
  handleUpdateCharacter: (id: string, key: string, value: any) => void
  isTraining: boolean
  fetchCharacters: (address: string) => void
  setIsTraining: React.Dispatch<React.SetStateAction<boolean>>
  isTrainingFinished: boolean
  setIsTrainingFinished: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
}

export const CharactersContext = createContext<ICharacterContextProps>({
  createdCharacters: createCharactersObject(),
  setCreatedCharacters: noop,
  selectedCharacter: null,
  setSelectedCharacter: noop,
  stonesToNextLevel: null,
  fetchCharacters: noop,
  setStonesToNextLevel: noop,
  handleUpdateCharacter: noop,
  isTraining: false,
  setIsTraining: noop,
  isTrainingFinished: false,
  setIsTrainingFinished: noop,
  loading: false
})


export const useCharactersData = () => {
  const charactersContext = useContext(CharactersContext);
  const charactersData = charactersContext;
  return useMemo(() => {
    return { ...charactersData };
  }, [charactersData]);
};


export const CharactersContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [createdCharacters, setCreatedCharacters] = useState<ICharacter[]>([])
  const [selectedCharacter, setSelectedCharacter] = useState<ICharacter>(null)
  const [stonesToNextLevel, setStonesToNextLevel] = useState(4)
  const [isTraining, setIsTraining] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isTrainingFinished, setIsTrainingFinished] = useState(false)

  const fetchCharacters = useCallback(async (address: string) => {
    setLoading(true)
    const data = await requestCharacters(address)
    if (data.users.length > 0) {
      const user = data.users[0]
      const userCharacters = user.characters as {
        id: string; name: CharacterNames;
        nickname: string; rarity: 'Normal' | 'Gold' | 'Diamond';
        level: number,
        trainingEnd: number,
        trainingTime: number,
        migrated: boolean
        inGame: boolean
      }[]
      const parsedCharacters = userCharacters.map(character => ({
        ...character,
        status: Math.floor(new Date().getTime() / 1000) < character.trainingEnd ? 'Fighting' : 'Resting',
        type: character.name,
        avatar: getCharacterAvatar(character.name),
        nickname: character.nickname || character.name,
        name: character.nickname || character.name,
        trainingTime: character.trainingTime,
        inGame: character.inGame,
        migrated: character.migrated,
        price: 0,
        category: 'characters',
        collection: character.id.includes('2-') ? 2 : 1,
      })) as ICharacter[]


      setCreatedCharacters(parsedCharacters)
    }
    setLoading(false)
  }, []);

  const handleUpdateCharacter = useCallback((id: string, key: string, value: any) => {
    setCreatedCharacters(createdCharacters => createdCharacters.map(character => {
      if (id === character.id) {
        return { ...character, [key]: value }
      }
      return character
    }))

  }, [setCreatedCharacters])

  useEffect(() => {
    setStonesToNextLevel(Math.pow(2, selectedCharacter?.level + 1))
  }, [setStonesToNextLevel, selectedCharacter])

  return (
    <CharactersContext.Provider
      value={{
        createdCharacters,
        setCreatedCharacters,
        selectedCharacter,
        setSelectedCharacter,
        stonesToNextLevel,
        setStonesToNextLevel,
        handleUpdateCharacter,
        isTraining,
        fetchCharacters,
        loading,
        setIsTraining,
        isTrainingFinished,
        setIsTrainingFinished
      }}
    >
      {children}
    </CharactersContext.Provider>
  )
}