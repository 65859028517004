import { getParsedEthersError } from '@enzoferey/ethers-error-parser'
import { ethers } from 'ethers'
import { toast } from 'sonner'
import React, { useCallback, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Characters } from 'src/abis'
import { useCharactersData } from 'src/context/CharactersContext'
import { useUserData } from 'src/context/UserContext'
import { useEthersSigner } from 'src/context/ethers'
import { ICharacter } from 'src/interfaces'
import { useAccount } from 'wagmi'

interface Props {
  character: ICharacter
}
const MigrateCharacter: React.FC<Props> = ({ character }) => {
  const { address } = useAccount()
  const signer = useEthersSigner()
  const { setCreatedUser, createdUser } = useUserData()
  const [approving, setApproving] = useState(false)
  const [migrating, setMigrating] = useState(false)
  const { fetchCharacters } = useCharactersData()
  const isNewCharacter = character && character.id.includes('2-')
  const approved = isNewCharacter ? createdUser.approvedNewCharactersMigrate : createdUser.approvedCharactersMigrate

  const approve = useCallback(async () => {
    const contractAddress = isNewCharacter ? process.env.REACT_APP_NEW_CHARACTERS_CONTRACT : process.env.REACT_APP_CHARACTERS_CONTRACT
    const contract = new ethers.Contract(contractAddress, Characters, signer)
    setApproving(true)
    try {
      await (await contract.setApprovalForAll(process.env.REACT_APP_MIGRATED_CHARACTERS_CONTRACT, true)).wait()
      setCreatedUser(createdUser => ({
         ...createdUser,
         approvedNewCharactersMigrate: !isNewCharacter ? createdUser.approvedNewCharactersMigrate : true,
         approvedCharactersMigrate: isNewCharacter ? createdUser.approvedCharactersMigrate : true
       }))
       toast.success('Successfully approved. You can now migrate your character')
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while approving. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setApproving(false)
  }, [signer, isNewCharacter, setCreatedUser])

  const migrate = useCallback(async () => {
    const contractAddress = process.env.REACT_APP_MIGRATED_CHARACTERS_CONTRACT
    const contract = new ethers.Contract(contractAddress, Characters, signer)
    setMigrating(true)
    try {
      const actualId = isNewCharacter ? character.id.split('-')[1] : character.id
      await (await contract.migrateCharacter(parseInt(actualId), !isNewCharacter)).wait()
      toast.success('Character Successfully migrated')
      fetchCharacters(address)
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while migrating character. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setMigrating(false)
  }, [signer, isNewCharacter, character, address, fetchCharacters])

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip className='nft-tooltip'>This NFT must be migrated in order to be used in game/sold.</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div {...triggerHandler}>
          <button ref={ref} disabled={migrating || approving} className="purple-btn w-100" onClick={approved ? migrate : approve}>
            {approving ? 'Approving...' : migrating ? 'Migrating...' : 'Migrate'}
          </button>
        </div>
      )}
    </OverlayTrigger>
  )
}
export default MigrateCharacter