import { useEffect } from 'react';
import { useSwitchNetwork } from 'wagmi';

const Switchnetwork = () => {
  const { switchNetwork } = useSwitchNetwork()
  // add class to page
  useEffect(() => {
    document.body.className = 'main switch';
    return () => {
      document.body.className = '';
    };
  }, []);

  if (!switchNetwork) return null

  //   end add class to page
  return (
    <>
      <div className="swicth-network-main">
        <img src="/images/logo.png" className="logo mx-auto d-flex pt-5" alt="" />
        <div className="switch-main-box">
          <div className="box switch-box">
            <p className="text-white font-24-700">Switch to the appropriate network to connect to Olympus Game</p>
            <img src="/images/line-divider.png" className="mt-3 mb-3 w-100" alt="" />
            <button className="blue-btn mt-3 mt-xl-5 mt-lg-5 mt-md-5  text-white " onClick={() => switchNetwork(parseInt(process.env.REACT_APP_MAIN_CHAIN_ID || ''))}>Switch network</button>
            <img src="/images/switch-zig-one.png" className="switch-zig-one" alt="" />
            <img src="/images/switch-zig-two.png" className="switch-zig-two" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Switchnetwork;
