import './App.css';
import './custom.css';
import Switchnetwork from './Components/Switchnetwork';
import Connect from './Components/Connect';
import Home from './Components/Home';
import Nftwallet from './Components/Nftwallet';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Marketplace from './Components/Marketplace';
import Renting from './Components/Renting';
import Play from './Components/Play';
import Token from './Components/Token';
import LogRocket from 'logrocket';
import Staking from './Components/Staking';
import Vote from './Components/Vote';
import Sidebar from './Components/Sidebar';
import Scrolltotop from './Components/Scrolltotop';
import { useUserData } from './context/UserContext';
import { Loader } from './Components/Loader';
import { useCharactersData } from './context/CharactersContext';
import { useVoteData } from './context/VoteContext';
import { verify } from 'web3-token'
import { useCookies } from 'react-cookie';
import Auth from './Components/Auth';
import Login from './Components/Login';
import Disconnect from './Components/Disconnect';
import { MarketplaceContextProvider, useMarketplaceContext } from './context/MarketplaceContext';
import { useAccount, useConnect, useNetwork } from 'wagmi';
import { useEthersProvider } from './context/ethers';
import AirdropPack from './Components/AirdropPack';

LogRocket.init('ly2yge/olympus');

function App () {
  const { fetchUserData, loading: fetchingUser, signature, setSignature, createdUser } = useUserData()
  const { fetchCharacters, loading: fetchingCharacters } = useCharactersData()
  const { fetchVoteData } = useVoteData()
  const [cookies, , removeCookie] = useCookies(['signature']);

  const provider = useEthersProvider()
  const { address, isConnected: connected, connector } = useAccount({
    onDisconnect: () => {
      removeCookie('signature')
      setSignature('')
      localStorage.removeItem('walletConnector')
    }
  })
  const { chain } = useNetwork()
  const wrongNetwork = chain?.id !== parseFloat(process.env.REACT_APP_MAIN_CHAIN_ID || '')

  const { fetchListings } = useMarketplaceContext()

  const { connect, connectors } = useConnect()
  useEffect(() => {
    const state = localStorage.getItem('walletConnector') || ''
    const matchingConnector = connectors.find((c) => c.id === state)
    if (matchingConnector && !connected) {
      connect({ connector: matchingConnector })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connector])

  useEffect(() => {
    const connectorId = connector?.id
    if (address && connected && !wrongNetwork && signature && connectorId) {
      localStorage.setItem('walletConnector', connectorId)
    }
  }, [address, connected, wrongNetwork, signature, connector])

  const verifySignature = async (signature: string) => {
    if (!address) return false
    try {
      const { address: responseAddress } = await verify(signature, { domain: process.env.HOST })
      return responseAddress.toLowerCase() === address.toLowerCase()
    } catch (e) {
      return true
    }
  }

  const init = async () => {
    if (process.env.REACT_APP_DEBUG_SIGNATURE) {
      setSignature(process.env.REACT_APP_DEBUG_SIGNATURE)
    }
    if (address && connected && !wrongNetwork && provider) {
      if (signature) {
        fetchUserData(address)
        fetchVoteData(address)
        fetchCharacters(address)
      } else {
        const savedSignature = cookies.signature
        if (savedSignature) {
          const matches = await verifySignature(savedSignature)
          if (matches) {
            setSignature(savedSignature as string)
          } else {
            removeCookie('signature')
          }
        }
      }
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, connected, wrongNetwork, signature])

  useEffect(() => {
    fetchListings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // back to top
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  window.addEventListener('scroll', toggleVisible);

  const loading = (fetchingUser || fetchingCharacters)

  const mustLogIn = createdUser.registered && !createdUser.logged

  const location = window.location.pathname
  if (location === '/disconnect') {
    return (<Disconnect />)
  }

  // end back to top
  return (
    <>
      <Scrolltotop> </Scrolltotop>
      {
        !connected ? <Connect /> :
          wrongNetwork ? <Switchnetwork /> :
            !signature ? <Auth /> :
              loading ? (<Loader fullScreen size="60px" />)
                : mustLogIn ? (<Login />) : (
                  <Sidebar>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/nftwallet" element={<Nftwallet />} />
                      <Route path="/airdroppack" element={<AirdropPack />} />
                      <Route path="/marketplace" element={<Marketplace segment={'first'} />} />
                      <Route path="/marketplace/user" element={<Marketplace segment='second' />} />
                      <Route path="/renting" element={<Renting />} />
                      <Route path="/play" element={<Play />} />
                      <Route path="/token" element={<Token />} />
                      <Route path="/staking" element={<Staking />} />
                      <Route path="/vote" element={<Vote />} />
                    </Routes>
                  </Sidebar>
                )
      }
      <img
        src="/images/scroll-up.svg"
        onClick={scrollToTop}
        alt="Go to top"
        style={{ display: visible ? 'inline' : 'none' }}
        className="scroll-top"
      />
    </>
  );
}
const content = () => (
  <Router>
    <MarketplaceContextProvider>
      <App />
    </MarketplaceContextProvider>
  </Router>
)
export default content;
