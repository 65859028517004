/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { noop } from "lodash";
import React, { createContext, useCallback, useState } from "react";
import { useMemo } from "react";
import { useContext } from "react";

interface VoteChoice {
  name: string;
  total: number;
}

interface IVoteContextProps {
    currentChoice: number;
    setCurrentChoice: React.Dispatch<React.SetStateAction<number>>;
    fetchVoteData: (address: string) => void;
    title: string;
    updateChoice: (choice: number, newTotal: number) => void;
    description: string;
    open: number;
    balance: number;
    close: number;
    snapshot: number;
    choices: VoteChoice[];
    loading
}

export const VoteContext = createContext<IVoteContextProps>({
    currentChoice: -1,
    setCurrentChoice: noop,
    fetchVoteData: noop,
    updateChoice: noop,
    title: '',
    close: -1,
    open: -1,
    snapshot: -1,
    description: '',
    choices: [],
    balance: 0,
    loading: true
})

export const useVoteData = () => {
  const voteContext = useContext(VoteContext);
  const voteData = voteContext;
  return useMemo(() => {
      return { ...voteData };
  }, [voteData]);
};

export const VoteContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [ loading, setLoading ] = useState(true)

    const [currentChoice, setCurrentChoice] = useState(-1);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [choices, setChoices] = useState<VoteChoice[]>([]);
    const [open, setOpen] = useState(-1);
    const [close, setClose] = useState(-1);
    const [snapshot, setSnapshot] = useState(-1);
    const [balance, setBalance] = useState(0);

    const fetchVoteData = useCallback(async (address: string) => {
      setLoading(true)
      const { data } = await axios.get(process.env.REACT_APP_API + '/api/vote?address=' + address)
      setTitle(data.title)
      setDescription(data.description)
      setOpen(data.open)
      setCurrentChoice(data.currentChoice)
      setBalance(data.balance)
      setClose(data.close)
      setSnapshot(data.snapshot)
      setChoices(data.choices)
      setLoading(false)
    }, []);

    const updateChoice = useCallback(async (choice: number, newTotal: number) => {
      const currentTotal = choices[choice] ? choices[choice].total : 0
      const difference = newTotal - currentTotal

      setChoices(choices.map((c, i) => {
        if (i === choice) {
          return {
            ...c,
            total: newTotal
          }
        } else if (i === currentChoice) {
          return {
            ...c,
            total: c.total - difference
          }
        }
        return c
      }))
    }, [choices])

    return (
        <VoteContext.Provider 
            value={{
                currentChoice,
                setCurrentChoice,
                title,
                description,
                choices,
                balance,
                fetchVoteData,
                loading,
                close,
                open,
                snapshot,
                updateChoice
            }}
        >
            {children}
        </VoteContext.Provider>
    )
}