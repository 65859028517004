import { ReactNode } from "react";

export interface IStoneObject {
  numberOfStones: number;
  id: string;
  isClaimed: boolean;
  isCreated: boolean;
  end: number;
}

export enum CharacterNames {
  Achilles = "Achilles",
  Apollo = "Apollo",
  Chimera = "Chimera",
  Medusa = "Medusa",
  Titan = "Titan",
  Zeus = "Zeus",
  Chiron = 'Chiron',
  Atlas = 'Atlas',
  Minotaur = 'Minotaur',
  Cyclop = 'Cyclop',
  Pegasus = 'Pegasus',
  Snake = 'Snake',
  SnakeArmy = 'Snake Army',
  Ares = 'Ares',
  Cerberus = 'Cerberus',
  Chronos = 'Chronos',
  Poseidon = 'Poseidon',
  Artemis = 'Artemis',
  Hercules = 'Hercules',
  Furies = 'Furies',
  FuriesArmy = 'Furies Army',
  Hades = 'Hades',
  SonsOfEgyptos = 'Sons of Egyptos',
  Danaids = 'Danaids',
  Hermes = 'Hermes',
  Cannon = 'Cannon',
  Spark = 'Spark',
  ArretDuTemps = 'Arret du temps',
  Swap = 'Swap',
  AngerOfZeus = 'Anger of Zeus',
  Blessing = 'Blessing',
  Wave = 'Wave'
}

export interface ICharacter {
  id: string;
  name: string;
  avatar: string;
  inGame: boolean;
  level: number;
  type: CharacterNames;
  rarity: "Normal" | "Gold" | "Diamond";
  status: "Resting" | "Fighting";
  trainingEnd: number;
  migrated: boolean;
  collection: number;
  category: "evolving_stones" | "characters" | "chests";
  price: number;
  trainingTime: number;
}

export interface IUser {
  id: string;
  olymp: number;
  stones: number;
  powder: number;
  powderToCreateStone: number;
  approvedPowder: boolean;
  approvedStarter: boolean;
  approvedEvolve: boolean;
  approvedNewEvolve: boolean;
  approvedBusdChests: boolean;
  approvedBusdOldMarketplace: boolean;
  approvedOlympBridge: boolean;
  approvedCharactersMigrate: boolean;
  approvedNewCharactersMigrate: boolean
  paid: boolean;
  inGameBalance: number;
  withdrawAmount: number;
  logged: boolean;
  claimingAvailable: Date;
  vestingBalance: number;
  purchasedStarter: boolean;
  claimedStarter: boolean;
  stakingBalance: number;
  registered: boolean;
  approvedBusdMarketplace: boolean;
  approvedChestsMarketplace: boolean;
  approvedNewChestsMarketplace: boolean;
  approvedMigratedCharactersMarketplace: boolean;
  approvedMigratedCharactersBridge: boolean;
  approvedStonesMarketplace: boolean;
  bUSD: number;
}

export interface IChest {
  id: string;
  name: string;
  image: string;
  rarity: number;
  collection: number;
  category: "evolving_stones" | "characters" | "chests";
  class: string;
  icon: string;
  openChestImage: string;
  level: number;
  price: number;
  quantity: number;
  boxInfo: {
    boxImage: string;
    charactersImage: string;
    characterChance: number;
    olympChance: number;
    olymp: string;
    stoneChance: number;
    powderChance: number;
    powder: string;
    goldCharacter: number;
    diamondCharacter: number;
  };
}

export interface IOption {
  label: ReactNode;
  value: string;
  className?: string;
}

export interface IFilter {
  category: string[];
  rarity: string[];
  level: {
    from: number;
    to: number;
  };
  priceRange: {
    from: number;
    to: number;
  };
}

export interface IMarketItem {
  id: string;
  collection: number;
  name: string;
  price: number;
  owner: string;
  oldContract: boolean;
  inGame: boolean;
  tokenId: string;
  date: number;
  rarity: string;
  category: "evolving_stones" | "characters" | "chests";
  level: number;
  type?: string;
  pastAuction?: boolean;
}

export interface INumberRange {
  from: number;
  to: number;
}
