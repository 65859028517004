import React, { useMemo } from 'react'
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { getImage } from 'src/data'
import { IMarketItem } from 'src/interfaces'
import { getCharacterAvatar } from 'src/utils'
import { formatDate, getBackground, getBorder, getTextColor } from './utils'
import { useAccount } from 'wagmi'

interface Props {
  onClick: () => void
  item: IMarketItem
}
const MarketplaceItem: React.FC<Props> = ({ onClick, item }) => {
  const { address } = useAccount()
  const owned = item.owner.toLowerCase() === address.toLowerCase()
  const listed = new Date(item.date * 1000)
  const formattedDate = formatDate(listed, false)

  const image = item.category === 'characters' ? getCharacterAvatar(item.name) : getImage(item)
  const border = useMemo(() => getBorder(item.rarity), [item.rarity])
  const imgBackground = useMemo(() => getBackground(item.rarity), [item.rarity])
  const textColor = useMemo(() => getTextColor(item.rarity), [item.rarity])

  return (
    <Col xl={3} lg={4} md={6} xs={12} className="mt-5 mt-xl-3 mt-lg-3 mt-md-3  nft-card-main" onClick={() => owned ? {} : onClick()}>
      <div className="nft-card">
        <div className="nft-inner-box">
          <img src={image} className={`img-fluid w-100 ${imgBackground}`} style={{ height: '180px' }} alt={item.name} />
          <div className="nft-text-area d-flex pt-3 pb-3">
            <img src={`/images/nft-${border}-line.png`} alt="" height={'53px'} style={{ width: '4px' }} />
            <div className="ps-3 d-flex w-100 nft-with-exchange">
              <div>
                <h3 className={`nft-title ${textColor}`}>{item.name}</h3>
                <p className={`nft-text ${textColor} mb-0`}>
                  {item.rarity[0].toUpperCase() + item.rarity.slice(1)} Rarity
                  {
                    item.level !== null && (
                      <span> - Level {item.level}</span>
                    )
                  }
                </p>
              </div>
              <div className="marketplace-mobile-diplay d-none">
                <p className="text-white mb-0 market-mobile-date mt-1">{formattedDate}</p>
                {
                  item.price === 0 ? <p className="mb-0">Not for sale</p> :
                    item.oldContract ? (
                      <p className={`${textColor} mb-0 market-mobile-price mt-1`}>
                        {item.price.toFixed(0)} $BUSD
                      </p>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip className='nft-tooltip'>(item.price).toLocaleString() + ' $BUSD + 5% (' + (item.price * 0.05).toLocaleString() + ' $BUSD) fee'</Tooltip>}
                      >
                        {({ ref, ...triggerHandler }) => (
                          <p className={`${textColor} mb-0 market-mobile-price mt-1`}  {...triggerHandler}>
                            {(item.price * 1.05).toLocaleString()} $BUSD
                          </p>
                        )}
                      </OverlayTrigger>
                    )
                }
              </div>
            </div>
          </div>
        </div>
        <div className="marketpalce-desktop-display">
          {
            !owned && (
              <div className="market-ower d-flex">
                <p className="text-white">Owner</p>
                <p className="text-white text-end flex-grow-1">
                  {owned ? 'You' : item.owner.slice(0, 6) + '...' + item.owner.slice(-4)}
                </p>
              </div>
            )
          }
          {
            item.date !== 0 && (
              <div className="market-listed d-flex mt-2">
                <p className="text-white">Listed</p>
                <p className="text-white text-end flex-grow-1">{formattedDate}</p>
              </div>
            )
          }
          {
            item.price === 0 ? (
              <div className="market-price d-flex mt-3">
                <p className={`text-end flex-grow-1 ${textColor} mb-0`}>Not for sale</p>
              </div>
            ) :
              item.oldContract ? (
                <div className="market-price d-flex mt-3">
                  <p className={textColor + ' mb-0'}>Price</p>
                  <p className={`text-end flex-grow-1 ${textColor} mb-0`}>
                    {item.price.toFixed(0)} $BUSD
                  </p>
                </div>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip className='nft-tooltip'>(item.price).toLocaleString() + ' $BUSD + 5% (' + (item.price * 0.05).toLocaleString() + ' $BUSD) fee'</Tooltip>}
                >
                  {({ ref, ...triggerHandler }) => (
                    <div className="market-price d-flex mt-3"  {...triggerHandler}>
                      <p className={textColor + ' mb-0'}>Price</p>
                      <p className={`text-end flex-grow-1 ${textColor} mb-0`}>
                        {(item.price * 1.05).toLocaleString()} $BUSD
                      </p>
                    </div>
                  )}
                </OverlayTrigger>
              )
          }
          {
            owned ? item.inGame ? (
              <div className="nft-button-area mt-1 py-2">
                <button className="purple-btn w-100" style={{ opacity: '40%' }} disabled>
                  Currently In Game
                </button>
              </div>
            ) : item.price > 0 ? (
              <div className="nft-button-area mt-1 py-2">
                <button className="purple-btn w-100"
                  onClick={onClick}>
                  Cancel
                </button>
              </div>
            ) : (
              <div className="nft-button-area mt-1 py-2">
                <button className="purple-btn w-100"
                  onClick={onClick}>
                  Sell
                </button>
              </div>
            ) : (
              <></>
            )
          }
        </div>
      </div>
    </Col>
  )
}

export default MarketplaceItem