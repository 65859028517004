import React, { ReactElement } from 'react'
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { SequenceConnector } from '@0xsequence/wagmi-connector'
import { publicProvider } from 'wagmi/providers/public'
import { bsc } from 'viem/chains'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc],
  [
    publicProvider()
  ]
)

type Connector =
  | InjectedConnector
  | WalletConnectConnector
  | SequenceConnector

const connectors = (): Connector[] => {
  return [
/*     new SequenceConnector({
      options: {
        defaultNetwork: process.env.REACT_APP_SEQUENCE_NETWORK,
        connect: {
          projectAccessKey: 'AQAAAAAAAG6jYgHVEgKYWuYEB5XOUh9L540',
          walletAppURL: 'https://sequence.app',
          app: 'olympus-game',
        },
      } as any,
      chains
    }), */
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: true
      }
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: process.env.REACT_APP_PROJECT_ID || '',
        isNewChainsStale: false,
        metadata: {
          name: 'Olympus Game',
          description: 'Olympus Game',
          url: 'https://app.olympus.game',
          icons: ['https://app.olympus.game/favicon.png']
        },
        qrModalOptions: {
          themeVariables: {
            '--wcm-z-index': '10000000000000000000'
          }
        }
      }
    })
  ]
}

const config = createConfig({
  autoConnect: true,
  connectors: connectors(),
  publicClient,
  webSocketPublicClient
})

const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <WagmiConfig config={config}>
      {children}
    </WagmiConfig>
  )
}

export default Web3ContextProvider
