import React, { useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import { IMarketItem } from 'src/interfaces'
import SellButton from './actions/Sell'
import { getBackground, getBorder, getTextColor } from './utils'
import { getCharacterAvatar } from 'src/utils'
import { getImage } from 'src/data'
import Unlist from './actions/Unlist'
import { useAccount } from 'wagmi'

interface Props {
  selectedItem: IMarketItem
}
const SellItem: React.FC<Props> = ({ selectedItem }) => {
  const [sellPrice, setSellPrice] = React.useState(0)
  const border = useMemo(() => getBorder(selectedItem.rarity), [selectedItem.rarity])
  const imgBackground = useMemo(() => getBackground(selectedItem.rarity), [selectedItem.rarity])
  const textColor = useMemo(() => getTextColor(selectedItem.rarity), [selectedItem.rarity])
  const { address } = useAccount()

  const image = selectedItem.category === 'characters' ? getCharacterAvatar(selectedItem.name) : getImage(selectedItem)
  const isOwned = selectedItem.price > 0 && selectedItem.owner === address.toLowerCase()

  return (
    <Row>
      <Col xl={5} lg={5} md={5} xs={6} className="pe-0 pe-xl-4 pe-lg-4 pe-md-4 sell-modal-left-col">
        <img src={image} className={`img-fluid w-100 ${imgBackground}`} alt={selectedItem.name} />
      </Col>
      <Col xl={7} lg={7} md={7} xs={6} className="sell-modal-right-col">
        <div className="item-modal-right-col d-flex">
          <div className="left-side text-start">
            <div className="d-flex">
              <img src={`/images/nft-${border}-line.png`} alt="" height={'100px%'} style={{ width: '4px' }} />
              <div className="ps-3">
                <div className="">
                  <h3 className={`item-modal-title ${textColor}`}>{selectedItem.name}</h3>
                </div>
                <p className={`item-modal-text mb-0 ${textColor}`}>
                  {selectedItem.rarity[0].toUpperCase() + selectedItem.rarity.slice(1)} Rarity
                  {
                    selectedItem.level !== null && (
                      <span> - Level {selectedItem.level}</span>
                    )
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="desktop-display right-side text-end flex-grow-1 ps-3 ps-xl-0 ps-lg-0 ps-md-0">
            <input type="number" placeholder="Price in USDT" className="sell-modal-input" value={sellPrice} onChange={e => setSellPrice(parseFloat(e.target.value))} />
            {
              isOwned ? (
                <Unlist item={selectedItem} buttonClasses='mx-auto' />
              ) : (
                <SellButton item={selectedItem} buttonClasses='mx-auto' sellPrice={sellPrice} />
              )
            }
          </div>
        </div>
      </Col>
      <div className="mobile-display">
        <input type="number" placeholder="Price in USDT" className="sell-modal-input" value={sellPrice} onChange={e => setSellPrice(parseFloat(e.target.value))} />
        {
          isOwned ? (
            <Unlist item={selectedItem} buttonClasses='' />
          ) : (
            <SellButton item={selectedItem} buttonClasses='' sellPrice={sellPrice} />
          )
        }
      </div>
    </Row>
  )
}

export default SellItem