import React, { useMemo } from 'react'
import { IMarketItem } from 'src/interfaces'
import { Col, Row } from 'react-bootstrap'
import { getImage } from 'src/data'
import { getCharacterAvatar } from 'src/utils'
import { formatDate, getBackground, getBorder, getTextColor } from './utils'
import Buy from './actions/Buy'
import { useAccount } from 'wagmi'

interface Props {
  item: IMarketItem
  setItem: (item: IMarketItem | null) => void
}
const ItemDetails: React.FC<Props> = ({ item, setItem }) => {
  const { address } = useAccount()
  const border = useMemo(() => getBorder(item.rarity), [item.rarity])
  const imgBackground = useMemo(() => getBackground(item.rarity), [item.rarity])
  const textColor = useMemo(() => getTextColor(item.rarity), [item.rarity])

  if (item == null) {
    return null
  }

  const listed = new Date(item.date * 1000)
  const image = item.category === 'characters' ? getCharacterAvatar(item.name) : getImage(item)
  const formattedDate = formatDate(listed, true)
  const owned = item != null && item.owner.toLowerCase() === address.toLowerCase()

  return (
    <Row className="market-first-tab-modal-row">
      <Col xl={5} lg={5} md={5} xs={6} className="pe-0 pe-xl-4 pe-lg-4 pe-md-4">
        <img src={image} className={`img-fluid w-100 ${imgBackground}  item-modal-left-img`} style={{ height: '180px' }} alt={item.name} />
      </Col>
      <Col xl={7} lg={7} md={7} xs={6}>
        <div className="desktop-display">
          <div className="item-modal-right-col d-flex">
            <div className="left-side text-start">
              <div className="d-flex">
                <img src={`/images/nft-${border}-line.png`} alt="" height={'100px%'} style={{ width: '4px' }} />
                <div className="ps-3">
                  <div className="d-flex">
                    <h3 className={`item-modal-title ${textColor}`}>{item.name}</h3>
                  </div>
                  <p className={`item-modal-text mb-0 ${textColor}`}>
                    {item.rarity[0].toUpperCase() + item.rarity.slice(1)} Rarity
                    {
                      item.level !== null && (
                        <span> - Level {item.level}</span>
                      )
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="right-side text-end flex-grow-1 ps-3 ps-xl-0 ps-lg-0 ps-md-0">
              <h3 className={`item-modal-price ${textColor} mb-0`}>
                {
                  item.price === 0 ? 'Not for sale' : `${item.price.toFixed(0)} $BUSD`
                }
              </h3>
              {
                !item.oldContract && item.price > 0 && (
                  <p className="item-modal-discount mb-0">+ 5% fee</p>
                )
              }
              <Buy buttonClasses='desktop-display' item={item} />            </div>
          </div>
          <div className="item-modal-inner-box text-start desktop-display">
            <p className="item-modal-inner-box-title mb-0">Owner:</p>
            <p className="item-modal-inner-box-text mb-0">{owned ? 'You' : item.owner}</p>
            <p className="item-modal-inner-box-title mt-3 mb-0">Date and Time of Listing:</p>
            <p className="item-modal-inner-box-text mb-0">{formattedDate}</p>
          </div>
        </div>
        <div className="mobile-display">
          <div className="item-modal-right-col d-flex">
            <div className="left-side text-start">
              <div className="d-flex">
                <img src="/images/nft-yellow-line.png" className="pt-2 pb-2" />
                <div className="ps-3">
                  <div className="d-flex">
                    <h3 className={`item-modal-title ${textColor}`}>{item.name}</h3>
                    <div className="item-i-btn desktop-display">
                      <img src="/images/i-icon.png" />
                    </div>
                  </div>
                  <p className={`item-modal-text mb-0 ${textColor}`}>
                    {item.rarity[0].toUpperCase() + item.rarity.slice(1)} Rarity
                    {
                      item.level !== null && (
                        <span> - Level {item.level}</span>
                      )
                    }
                  </p>
                  <div className="right-side text-end flex-grow-1 ps-0">
                    <h3 className={`item-modal-price ${textColor} mb-0`}>
                      {
                        item.price === 0 ? 'Not for sale' : `${item.price.toFixed(0)} $BUSD`
                      }
                    </h3>
                    {
                      !item.oldContract && item.price > 0 && (
                        <p className="item-modal-discount mb-0">+ 5% fee</p>
                      )
                    }
                    <Buy buttonClasses='desktop-display' item={item} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <div className="mobile-display">
        <div className="d-flex mt-4 pt-1" style={{ gap: '10px' }}>
          <div className=" mobile-display">
            <div className="item-i-btn">
              <img src="/images/i-for-info-mobile.svg" />
            </div>
          </div>
          <p className="font-16-500 mb-0 my-auto" style={{ fontSize: '16px' }}>
            Character details
          </p>
        </div>

        <div className="item-modal-inner-box text-start mt-3 pt-1">
          <p className="item-modal-inner-box-title mb-0">Owner:</p>
          <p className="item-modal-inner-box-text mb-0">{owned ? 'You' : item.owner}</p>
          <p className="item-modal-inner-box-title mt-3 mb-0">Date and Time of Listing:</p>
          <p className="item-modal-inner-box-text mb-0">{formattedDate}</p>
        </div>
        <Buy buttonClasses='' item={item} />
      </div>
    </Row>

  )
}

export default ItemDetails