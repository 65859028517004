import { useEffect } from 'react';
const Login = () => {
  // add class to page
  useEffect(() => {
    document.body.className = 'main switch';
    return () => {
      document.body.className = '';
    };
  }, []);
  //   end add class to page
  return (
    <>
      <div className="swicth-network-main">
        <img src="/images/logo.png" className="logo mx-auto d-flex pt-5 pb-2" />
        <div className="switch-main-box">
          <div className="box switch-box">
            <p className="text-white font-20-700">In order to avoid any accounts related problems, please make sure to login in the game before proceeding.</p>
            <img src="/images/line-divider.png" className="mt-3 mb-3 w-100" />
            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
              <a className="blue-btn mt-4 mt-xl-3 mt-lg-3 mt-md-5 text-white" style={{ textDecoration: 'none' }} href="https://play.olympus.game" target="_blank" rel='noreferrer'>
                Open the Game
              </a>
              <button className="blue-btn mt-3 text-white" onClick={() => window.location.reload()}>
                Done
              </button>
            </div>
            <img src="/images/switch-zig-one.png" className="switch-zig-one" />
            <img src="/images/switch-zig-two.png" className="switch-zig-two" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
