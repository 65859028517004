import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { useState } from 'react';
import ItemDetails from './ItemDetails'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Modal from 'react-bootstrap/Modal';
import { useEffect } from 'react';
import { useUserData } from 'src/context/UserContext';
import { formatValue } from 'src/utils';
import { useMarketplaceContext } from 'src/context/MarketplaceContext';
import { IMarketItem } from 'src/interfaces';
import AllItems from './AllItems';
import UserItems from './UserItems';
import { useAccount } from 'wagmi';

interface Props {
  segment: string;
}
const Marketplace: React.FC<Props> = props => {
  const { searchTerm, setSearchTerm } = useMarketplaceContext()
  const [openedItem, setOpenedItem] = useState<IMarketItem | null>(null)

  const { address } = useAccount()
  const olympBalance = useUserData().createdUser.olymp
  useEffect(() => {
    document.body.className = 'main marketplace';
    return () => {
      document.body.className = '';
    };
  }, []);

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="content-top-bar">
          <Row>
            <Col xl={9} lg={9}>
              <div className="d-flex">
                <div className="box top-bar-box me-4 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href="https://whitepaper.olympus.game/characters" target='_blank' rel="noreferrer">
                    <p className="text-white m-0">
                      <img src="/images/character-icon.png" className="pe-3" /> NFT Characters
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 me-4">
                  <a href="https://whitepaper.olympus.game/" target='_blank' rel="noreferrer">
                    <p className="text-white mb-0">
                      <img src="/images/white-paper-icon.png" className="pe-3" /> Whitepaper
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <p className="text-white mb-0">
                    <img src="/images/top-bar-third-icon.png" className="pe-3" />{formatValue(olympBalance)} OLYMP in wallet
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3}>
              <div className="box top-right-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 py-3 float-end">
                <p className="text-white mb-0 top-right-text d-flex">
                  {' '}
                  {address.slice(0, 2)}...{address.slice(-5)} <img src="/images/circle-icon.png" className="" />
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <Tab.Container defaultActiveKey={props.segment}>
          <Row className="mt-4 mt-xl-5 mt-lg-5 mt-md-5 row">
            <Col xl={7} lg={7} md={7} xs={12}>
              <Nav variant="pills" className="tabs-head">
                <Nav.Item>
                  <Nav.Link eventKey="first" className="tab-title">
                    Marketplace
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" className="tab-title">
                    My Collection
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>

            <Col xl={5} lg={5} md={5} xs={12}>
              <div className="search-bar-conatiner  ps-0 ps-xl-4 ps-lg-4 ps-md-4 mt-4 mt-xl-0 mt-lg-0 mt-md-0">
                <Form.Group className="" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Search" className="seach-bar" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                </Form.Group>
              </div>
            </Col>
          </Row>

          <Tab.Content>
            <AllItems openedItem={openedItem} setOpenedItem={setOpenedItem} />
            <UserItems />
          </Tab.Content>
        </Tab.Container>
      </div>

      <Modal show={openedItem != null} onHide={() => setOpenedItem(null)} backdrop="static" keyboard={false} className="item-modal ">
        <Modal.Header closeButton className="border-0 p-0 market-first-tab-modal-main" />
        <Modal.Body className="item-modal-body market-first-tab-modal">
          {
            openedItem !== null && (
              <ItemDetails item={openedItem} setItem={setOpenedItem} />
            )
          }
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Marketplace;
