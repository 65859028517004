import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery/dist/jquery.min.js';
import { UserContextProvider } from './context/UserContext';
import { CharactersContextProvider } from './context/CharactersContext';
import Web3ContextProvider from './context/Web3Provider';
import { Toaster } from 'sonner';
import { VoteContextProvider } from './context/VoteContext';
import { CookiesProvider } from 'react-cookie';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Toaster position='top-right' richColors />
      <Web3ContextProvider>
        <UserContextProvider>
          <VoteContextProvider>
            <CharactersContextProvider>
              <App />
            </CharactersContextProvider>
          </VoteContextProvider>
        </UserContextProvider>
      </Web3ContextProvider>
    </CookiesProvider>
  </React.StrictMode >
);
