/* eslint-disable react-hooks/exhaustive-deps */
import { noop } from "lodash";
import React, { createContext, useCallback, useState } from "react";
import { useMemo } from "react";
import { useContext } from "react";
import { IUser } from "../interfaces";
import { createUserObject } from "../utils";
import { requestUserData } from "../queries";
import { ethers } from "ethers";
import { Characters, ERC20, Starter, Vesting } from "src/abis";
import { Contract as MulticallContract, Provider } from "ethers-multicall";
import { JsonRpcSigner } from "@ethersproject/providers";
import { sign } from "web3-token";
import { useCookies } from "react-cookie";
import { toast } from "sonner";
import axios from "axios";

interface IUserContextProps {
  createdUser: IUser
  setCreatedUser: React.Dispatch<React.SetStateAction<IUser>>
  handleUpdateUser: (data: any, key: string, value: any) => void
  fetchUserData: (address: string) => void
  chests: string[],
  vestingData: VestingData,
  signMessage: (address: string, provider: JsonRpcSigner) => void,
  signature: string,
  setVestingData: React.Dispatch<React.SetStateAction<VestingData>>,
  fetchVestingData: (address: string) => void,
  setSignature: (signature: string) => void,
  loading
}

export const UserContext = createContext<IUserContextProps>({
  createdUser: createUserObject(),
  setCreatedUser: noop,
  fetchUserData: noop,
  fetchVestingData: noop,
  handleUpdateUser: noop,
  chests: [],
  signature: '',
  vestingData: {
    approvedAlpha: false,
    approvedBeta: false,
    availableAmount: 0,
    vestedAlpha: 0,
    vestedBeta: 0,
    unvestedAlpha: 0,
    unvestedBeta: 0
  },
  setVestingData: noop,
  signMessage: noop,
  setSignature: noop,
  loading: true
})

export const useUserData = () => {
  const userContext = useContext(UserContext);
  const userData = userContext;
  return useMemo(() => {
    return { ...userData };
  }, [userData]);
};

export interface VestingData {
  approvedAlpha: boolean,
  approvedBeta: boolean,
  availableAmount: number,
  vestedAlpha: number,
  vestedBeta: number,
  unvestedAlpha: number,
  unvestedBeta: number
}

export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [createdUser, setCreatedUser] = useState<IUser>(createUserObject())
  const [loading, setLoading] = useState(true)
  const [chests, setChests] = useState([])
  const [signature, setSignature] = useState('')
  const [, setCookie,] = useCookies(['signature']);
  const [vestingData, setVestingData] = useState<VestingData>({
    approvedAlpha: false,
    approvedBeta: false,
    availableAmount: 0,
    vestedAlpha: 0,
    vestedBeta: 0,
    unvestedAlpha: 0,
    unvestedBeta: 0
  })

  const busdAddress = process.env.REACT_APP_BUSD_CONTRACT || ''
  const stonesAddress = process.env.REACT_APP_STONES_CONTRACT || ''
  const powdersAddress = process.env.REACT_APP_POWDER_CONTRACT || ''
  const olympAddress = process.env.REACT_APP_OLYMP_CONTRACT || ''
  const charactersAddress = process.env.REACT_APP_CHARACTERS_CONTRACT || ''
  const newCharactersAddress = process.env.REACT_APP_NEW_CHARACTERS_CONTRACT || ''
  const migratedCharactersAddress = process.env.REACT_APP_MIGRATED_CHARACTERS_CONTRACT || ''
  const starterAddress = process.env.REACT_APP_STARTER_CONTRACT || ''

  const fetchVestingData = useCallback(async (address: string) => {
    const vestingAddress = process.env.REACT_APP_VESTING_CONTRACT || ''
    const alphaOlympAddress = process.env.REACT_APP_ALPHA_OLYMP_CONTRACT || ''
    const betaOlympAddress = process.env.REACT_APP_BETA_OLYMP_CONTRACT || ''

    const provider = await new ethers.providers.JsonRpcProvider(process.env.REACT_APP_MAIN_CHAIN_RPC);
    const ethcallProvider = new Provider(provider);
    await ethcallProvider.init()

    const alphaOlympMulticall = new MulticallContract(alphaOlympAddress, ERC20)
    const betaOlympMulticall = new MulticallContract(betaOlympAddress, ERC20)
    const vestingMulticall = new MulticallContract(vestingAddress, Vesting)
    const calls = [
      alphaOlympMulticall.allowance(address, vestingAddress),
      betaOlympMulticall.allowance(address, vestingAddress),
      vestingMulticall.availableAmount(address),
      vestingMulticall.vestedAlpha(address),
      vestingMulticall.vestedBeta(address),
      alphaOlympMulticall.balanceOf(address),
      betaOlympMulticall.balanceOf(address)
    ]
    const response = await ethcallProvider.all(calls)
    const availableAmount = parseInt(response[2]._hex, 16) / 1e18
    const vestedAlpha = parseInt(response[3]._hex, 16) / 1e18
    const vestedBeta = parseInt(response[4]._hex, 16)
    const unvestedAlpha = parseInt(response[5]._hex, 16) / 1e18
    const unvestedBeta = parseInt(response[6]._hex, 16)
    const approvedAlphaOlymp = parseInt(response[0]._hex, 16) / 1e18 > unvestedAlpha
    const approvedBetaOlymp = parseInt(response[1]._hex, 16) / 1e18 > unvestedBeta
    setVestingData({
      approvedAlpha: approvedAlphaOlymp,
      approvedBeta: approvedBetaOlymp,
      availableAmount,
      vestedAlpha,
      vestedBeta,
      unvestedAlpha,
      unvestedBeta
    })
  }, [])

  const fetchUserData = useCallback(async (baseAddress: string) => {
    const address = (process.env.REACT_APP_DEBUG_ADDRESS || baseAddress).toLowerCase()
    console.log(address)
    setLoading(true)
    const data = await requestUserData(address)
    const provider = await new ethers.providers.JsonRpcProvider(process.env.REACT_APP_MAIN_CHAIN_RPC);

    const ethcallProvider = new Provider(provider);
    await ethcallProvider.init()

    const busdMulticall = new MulticallContract(busdAddress, ERC20)
    const olympMulticall = new MulticallContract(olympAddress, ERC20)
    const powdersMulticall = new MulticallContract(powdersAddress, ERC20)
    const stonesMulticall = new MulticallContract(stonesAddress, ERC20)
    const charactersMulticall = new MulticallContract(charactersAddress, Characters)
    const newCharactersMulticall = new MulticallContract(newCharactersAddress, Characters)
    const migratedCharactersMulticall = new MulticallContract(migratedCharactersAddress, Characters)
    const starterMulticall = new MulticallContract(starterAddress, Starter)
    fetchVestingData(address)

    const calls = [
      busdMulticall.allowance(address, process.env.REACT_APP_BONUS_CHESTS_CONTRACT),
      busdMulticall.balanceOf(address),
      olympMulticall.balanceOf(address),
      powdersMulticall.balanceOf(address),
      stonesMulticall.balanceOf(address),
      stonesMulticall.allowance(address, process.env.REACT_APP_CHARACTERS_CONTRACT),
      busdMulticall.allowance(address, process.env.REACT_APP_MARKETPLACE_CONTRACT),
      busdMulticall.allowance(address, process.env.REACT_APP_OLD_MARKETPLACE_CONTRACT),
      stonesMulticall.allowance(address, process.env.REACT_APP_NEW_CHARACTERS_CONTRACT),
      charactersMulticall.isApprovedForAll(address, process.env.REACT_APP_MIGRATED_CHARACTERS_CONTRACT),
      newCharactersMulticall.isApprovedForAll(address, process.env.REACT_APP_MIGRATED_CHARACTERS_CONTRACT),
      migratedCharactersMulticall.isApprovedForAll(address, process.env.REACT_APP_CHARACTERS_BRIDGE_CONTRACT),
      starterMulticall.purchased(address),
      starterMulticall.claimed(address),
      olympMulticall.allowance(address, starterAddress)
    ]
    const response = await ethcallProvider.all(calls)

    const chestsAllowance = parseInt(response[0]._hex, 16) / 1e18
    const balance = parseInt(response[1]._hex, 16) / 1e18
    const olympBalance = parseInt(response[2]._hex, 16) / 1e18
    const powdersBalance = parseInt(response[3]._hex, 16)
    const stonesBalance = parseInt(response[4]._hex, 16)
    const evolveAllowance = parseInt(response[5]._hex, 16)
    const marketplaceAllowance = parseInt(response[6]._hex, 16) / 1e18
    const oldMarketplaceAllowance = parseInt(response[7]._hex, 16) / 1e18
    const newEvolveAllowance = parseInt(response[8]._hex, 16)
    const approvedCharactersMigrate = response[9]
    const approvedNewCharactersMigrate = response[10]
    const approvedMigratedCharactersBridge = response[11]
    const purchased = response[12]
    const claimed = response[13]
    const approvedStarter = parseInt(response[14]._hex, 16) / 1e18

    let paid = false;
    let registered = false;
    let inGameBalance = 0
    let logged = false;
    try {
      const usedSignature = process.env.REACT_APP_DEBUG_SIGNATURE || signature
      const request = await axios.get(
        `${process.env.REACT_APP_API}/api/user?address=${address}&signature=${usedSignature}`
      )
      const user = request.data
      paid = user.paid
      registered = user.registered
      inGameBalance = user.olymp
      logged = user.logged
    } catch (e) {
      const error = e as { response?: { data?: { error?: string } } }
      const message = error.response?.data?.error || 'An unexpected error has occurred while fetching user data.'
      toast.error(message)
      setSignature('')
      setCookie('signature', '')
      setLoading(false)
      return
    }

    if (data.users.length > 0) {
      const user = data.users[0]
      const chests = []
      user.chests.filter(chest => chest.amount > 0).forEach((chest: { rarity: string, amount: number }) => [...new Array(chest.amount)].forEach(index => chests.push(chest.rarity)))
      setChests(chests)
      setCreatedUser({
        ...createdUser,
        olymp: olympBalance,
        stones: stonesBalance,
        inGameBalance,
        powder: powdersBalance,
        paid,
        claimingAvailable: new Date(user.claimingAvailable * 1000),
        withdrawAmount: parseFloat(ethers.utils.formatEther(user.withdrawAmount).toString()),
        registered,
        logged,
        approvedEvolve: evolveAllowance > 0,
        approvedNewEvolve: newEvolveAllowance > 0,
        approvedBusdChests: chestsAllowance > 0,
        approvedBusdMarketplace: marketplaceAllowance > 5000,
        approvedOlympBridge: user.amountApprovedOlympBridge > 5000,
        approvedBusdOldMarketplace: oldMarketplaceAllowance > 5000,
        approvedPowder: user.amountApprovedFurnace > 0,
        approvedCharactersMigrate,
        approvedNewCharactersMigrate,
        approvedMigratedCharactersBridge,
        bUSD: balance,
        approvedMigratedCharactersMarketplace: user.approvedMigratedCharactersMarketplace,
        approvedStonesMarketplace: user.approvedStonesMarketplace,
        approvedChestsMarketplace: user.approvedChestsMarketplace,
        approvedNewChestsMarketplace: user.approvedNewChestsMarketplace,
        claimedStarter: claimed,
        purchasedStarter: purchased,
        approvedStarter: approvedStarter > 5000
      })
    } else {
      setCreatedUser({
        ...createdUser,
        paid,
        registered,
        inGameBalance,
        approvedCharactersMigrate,
        approvedNewCharactersMigrate,
        approvedEvolve: evolveAllowance > 0,
        approvedBusdChests: chestsAllowance > 0,
        approvedOlympBridge: false,
        approvedMigratedCharactersBridge: false,
        approvedBusdMarketplace: marketplaceAllowance > 5000,
        approvedBusdOldMarketplace: oldMarketplaceAllowance > 5000,
        olymp: olympBalance,
        bUSD: balance,
        logged,
        claimedStarter: claimed,
        purchasedStarter: purchased,
        approvedStarter: approvedStarter > 5000
      })
    }
    setLoading(false)
  }, [signature]);

  const signMessage = useCallback(async (address: string, signer: JsonRpcSigner) => {
    const message = `Authenticating myself (${address}) to access Olympus Game.`
    try {
      const signature = await sign(async msg => await signer.signMessage(msg), {
        expires_in: '2 days',
        domain: process.env.REACT_APP_HOSTNAME,
        statement: message
      })
      setSignature(signature)
    } catch (rawError) {
      const error = rawError as { message: string }
      toast.error(error.message)
    }
  }, [])


  const handleUpdateUser = useCallback((data: any, key: string, value: any) => {
    setCreatedUser({ ...data, [key]: value })
  }, [setCreatedUser])

  return (
    <UserContext.Provider
      value={{
        createdUser,
        setCreatedUser,
        signMessage,
        signature,
        setSignature,
        handleUpdateUser,
        fetchUserData,
        setVestingData,
        fetchVestingData,
        vestingData,
        loading,
        chests,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}