import { getParsedEthersError } from '@enzoferey/ethers-error-parser'
import { ethers } from 'ethers'
import { toast } from 'sonner'
import { useCallback, useState } from 'react'
import { Button, Col, Row, Tab } from 'react-bootstrap'
import { ERC20, Vesting as VestingAbi } from 'src/abis'
import { useUserData } from 'src/context/UserContext'
import { useEthersSigner } from 'src/context/ethers'
import { formatValue } from 'src/utils'
import { useAccount } from 'wagmi'

const Vesting = () => {
  const { vestingData, setVestingData, fetchVestingData, handleUpdateUser, createdUser } = useUserData()
  const { address } = useAccount()
  const signer = useEthersSigner()
  const { unvestedAlpha, unvestedBeta, vestedAlpha, vestedBeta, approvedAlpha, approvedBeta, availableAmount } = vestingData
  const unvestedIcoTokens = unvestedAlpha + unvestedBeta
  const vestedIcoTokens = vestedAlpha + vestedBeta
  const mustInit = unvestedIcoTokens > 0 && vestedIcoTokens === 0
  const [loading, setLoading] = useState<'APPROVING' | 'CLAIMING' | 'INITIALIZING' | ''>('')

  const initButtonText = loading === 'APPROVING' ? 'Approving...' : loading === 'INITIALIZING' ? 'Starting vesting...' : !approvedAlpha ? 'Approve $aOLYMP to start vesting' : !approvedBeta ? 'Approve $bOLYMP to start vesting' : 'Start vesting'

  const approve = useCallback(async (token: 'alpha' | 'beta') => {
    const contractAddress = token === 'alpha' ? process.env.REACT_APP_ALPHA_OLYMP_CONTRACT : process.env.REACT_APP_BETA_OLYMP_CONTRACT
    const contract = new ethers.Contract(contractAddress, ERC20, signer)
    setLoading('APPROVING')
    try {
      await (await contract.approve(process.env.REACT_APP_VESTING_CONTRACT, ethers.constants.MaxUint256)).wait()
      toast.success(`Successfully approved ${token === 'alpha' ? '$aOLYMP' : '$bOLYMP'}`)
      setVestingData((prev) => ({
        ...prev,
        approvedAlpha: token === 'alpha' ? true : prev.approvedAlpha,
        approvedBeta: token === 'beta' ? true : prev.approvedBeta,
      }))
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while approving. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setLoading('')
  }, [signer, setVestingData])

  const startVesting = useCallback(async () => {
    setLoading('INITIALIZING')
    try {
      const contract = new ethers.Contract(process.env.REACT_APP_VESTING_CONTRACT, VestingAbi, signer)
      await (await contract.startVesting()).wait()
      toast.success(`Successfully started vesting`)
      fetchVestingData(address)
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while starting vesting. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage)
    }
    setLoading('')
  }, [signer, address, fetchVestingData])

  const claim = useCallback(async () => {
    setLoading('CLAIMING')
    try {
      const contract = new ethers.Contract(process.env.REACT_APP_VESTING_CONTRACT, VestingAbi, signer)
      await (await contract.claim()).wait()
      toast.success(`Successfully claimed tokens`)
      fetchVestingData(address)
      setVestingData((prev) => ({
        ...prev,
        availableAmount: 0
      }))
      handleUpdateUser(createdUser, 'olymp', createdUser.olymp + availableAmount)
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while starting vesting. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage)
    }
    setLoading('')
  }, [signer, address, fetchVestingData, setVestingData, handleUpdateUser, createdUser, availableAmount])

  const handleClick = useCallback(async () => {
    if (mustInit) {
      if (!approvedAlpha) {
        await approve('alpha')
      } else if (!approvedBeta) {
        await approve('beta')
      } else {
        startVesting()
      }
    } else if (availableAmount > 0) {
      claim()
    }
  }, [mustInit, approvedAlpha, approvedBeta, approve, startVesting, availableAmount, claim])


  return (
    <Tab.Pane eventKey="vesting">
      <div className="vestiing-bg">
        <Row className="vesting-tab-content">
          <Col xl={12}>
            <div>
              <h1 className="main-heading color-purple text-capitalize font-60 text-center ">Vesting</h1>
              <div className="box p-5 token-tab-content-box vesting-tab-content-box">
                {
                  mustInit && (
                    <>
                      <div className="d-flex fs-5 align-items-start position-relative mb-3 vote-inner-content" style={{ marginTop: '-20px' }}>
                        You have not started the vesting yet. Please click on the button below to start the vesting.
                      </div>
                      <img src="/images/line-divider.png" className="w-100 desktop-display" />
                      <Button className="purple-btn mt-5 justify-content-center d-flex mx-auto" onClick={handleClick} disabled={loading !== ''}>{initButtonText}</Button>
                    </>
                  )
                }
                {
                  !mustInit && (
                    <>
                      <div className="d-flex vesting-text-line">
                        <p className="font-24-700 text-white mb-0 mb-xl-4 mb-lg-4 mb-md-4">OLYMP in vesting</p>
                        <p className="font-24-700 text-white text-end flex-grow-1  mb-0 mb-xl-4 mb-lg-4 mb-md-4">{formatValue(vestedAlpha + vestedBeta)}</p>
                      </div>
                      <img src="/images/line-divider.png" className="w-100 mobile-display mb-3 mt-3" />
                      <div className="d-flex vesting-text-line">
                        <p className="font-24-700 text-white mb-0 mb-xl-4 mb-lg-4 mb-md-4">Available to claim</p>
                        <p className="font-24-700 text-white text-end flex-grow-1  vesting-mobile-box-text mb-0 mb-xl-4 mb-lg-4 mb-md-4">{formatValue(availableAmount)}</p>
                      </div>
                      <img src="/images/line-divider.png" className="w-100 desktop-display" />
                      <Button className="purple-btn mt-5 justify-content-center d-flex mx-auto"
                        onClick={handleClick} disabled={loading === 'CLAIMING' || availableAmount === 0}>
                        {loading === 'CLAIMING' ? 'Claiming...' : 'Claim'}
                      </Button>
                    </>
                  )
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Tab.Pane>
  )
}

export default Vesting