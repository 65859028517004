import { useEffect } from 'react';
import { useAccount, useConnect } from 'wagmi';
const Connect = () => {
  // add class to page

  const { isConnected: connected, isConnecting } = useAccount()
  const { connect, connectors, pendingConnector } = useConnect({
    chainId: 56,
    onSuccess: (data) => {
      if (data.connector?.id === 'walletConnect') {
        window.location.reload()
      }
    }
  })
  const walletConnectConnector = connectors.find(c => c.id === 'walletConnect')
  const injectedConnector = connectors.find(c => c.id === 'injected')
  const sequenceConnector = connectors.find(c => c.id === 'sequence')
  const hasInjected = injectedConnector && injectedConnector.ready
  const hasWalletConnect = walletConnectConnector && walletConnectConnector.ready
  const pendingId = pendingConnector ? pendingConnector.id : ''

  useEffect(() => {
    document.body.className = 'main connect';
    return () => {
      document.body.className = '';
    };
  }, []);
  //   end add class to page
  return (
    <>
      <div className="connect text-center pt-5">
        <img src="/images/logo.png" className="mb-5 logo" />
        <h1 className="text-uppercase font-400-70 color-purple main-heading">Connect</h1>
        <div className="connect-box-main">
          <div className="box connect-box">

{/*             <div className="connect-inner-card d-flex" onClick={() => !isConnecting && connect({
              connector: sequenceConnector,
              chainId: parseInt(process.env.REACT_APP_MAIN_CHAIN_ID || '')
            })}>
              <img src="/images/sequence-icon.png" />
              <p className="connect-text text-white my-auto ps-3 ps-xl-0 ps-lg-0 ps-md-0 text-center flex-grow-1">
                {
                  pendingId === 'sequence' ? 'Connecting...' : connected ? 'Connected' : 'Sequence.app'
                }
              </p>
            </div>
            <p className="font-16-500 text-white mt-4 mb-0">Create a wallet in 2 clicks just with your email</p>
            <img src="/images/line-divider.png" className="mt-3 mb-3 w-100" /> * */}

            {
              hasInjected && (
                <div className="connect-inner-card d-flex mt-3" onClick={() => !isConnecting && connect({
                  connector: injectedConnector,
                  chainId: parseInt(process.env.REACT_APP_MAIN_CHAIN_ID || '')
                })}>
                  <img src="/images/metamask-iocn.png" alt="" />
                  <p className="connect-text text-white my-auto ps-3 ps-xl-0 ps-lg-0 ps-md-0 text-center flex-grow-1">
                    {
                      pendingId === injectedConnector.id ? 'Connecting...' : connected ? 'Connected' : 'Metamask'
                    }
                  </p>
                </div>
              )
            }

            {
              hasWalletConnect && (
                <div className="connect-inner-card d-flex mt-3" onClick={() => !isConnecting && connect({
                  connector: walletConnectConnector,
                  chainId: parseInt(process.env.REACT_APP_MAIN_CHAIN_ID || '')
                })}>
                  <img src="/images/WalletConnect-icon.png" alt="" />
                  <p className="connect-text text-white my-auto ps-3 ps-xl-0 ps-lg-0 ps-md-0 text-center flex-grow-1">
                    {
                      pendingId === walletConnectConnector.id ? 'Connecting...' : connected ? 'Connected' : 'WalletConnect'
                    }
                  </p>
                </div>
              )
            }

          </div>
          <img src="/images/connect-icon-one.png" className="connect-zig-one" alt="" />
          <img src="/images/connect-icon-two.png" className="connect-zig-two" alt="" />
        </div>
      </div>
    </>
  );
};
export default Connect;
