import stoneImage from "./images/marketplace/evolving-stone-marketplace.png";
import commonChestImage from "./images/marketplace/common-chest-marketplace.png";
import uncommonChestImage from "./images/marketplace/uncommon-chest-marketplace.png";
import rareChestImage from "./images/marketplace/rare-chest-marketplace.png";
import legendaryChestImage from "./images/marketplace/legendary-chest-marketplace.png";
import atlasChestImage from "./images/marketplace/atlas-chest-marketplace.png";
import bonusChestImage from "./images/marketplace/bonus-chest-marketplace.png";
import achillesImage from "./images/marketplace/achilles-marketplace.png";
import medusaImage from "./images/marketplace/medusa-marketplace.png";
import apolonImage from "./images/marketplace/apolon-marketplace.png";
import chimeraImage from "./images/marketplace/chimera-marketplace.png";
import titanImage from "./images/marketplace/titan-marketplace.png";
import zeusImage from "./images/marketplace/zeus-marketplace.png";
import atlasImage from "./images/marketplace/atlas-marketplace.png";
import minotaurImage from "./images/marketplace/minotaur-marketplace.png";
import chironImage from "./images/marketplace/chiron-marketplace.png";
import cyclopImage from "./images/marketplace/cyclop-marketplace.png";

import stoneImageItem from "./images/items/evolving-stone-item.png";
import commonBoxImageItem from "./images/items/common-chest-item.png";
import uncommonBoxImageItem from "./images/items/uncommon-chest-item.png";
import rareBoxImageItem from "./images/items/rare-chest-item.png";
import atlasBoxImageItem from "./images/items/atlas-chest-item.png";
import bonusBoxImageItem from "./images/items/bonus-chest-item.png";
import legendaryBoxImageItem from "./images/items/legendary-chest-item.png";
import achillesImageItem from "./images/items/achilles-item.png";
import apolonImageItem from "./images/items/apolon-item.png";
import chimeraImageItem from "./images/items/chimera-item.png";
import medusaImageItem from "./images/items/medusa-item.png";
import titanImageItem from "./images/items/titan-item.png";
import zeusImageItem from "./images/items/zeus-item.png";
import atlasImageItem from "./images/items/atlas-item.png";
import chironImageItem from "./images/items/chiron-item.png";
import minotaurImageItem from "./images/items/minotaur-item.png";
import cyclopImageItem from "./images/items/cyclop-item.png";
import { CharacterNames, IChest, IMarketItem } from "./interfaces";


export const getDataById = (items: IMarketItem[], id: string) => {
  return items.find(x => x.id === id)
}

export const addToCollection = (data: IMarketItem) => {
  collection.push(data)
  return
}

export const getItemImage = (item: IMarketItem) => {
  if (!item || item === null) return ''
  switch (item.category) {
    case 'characters': {
      switch (item.name) {
        case CharacterNames.Achilles:
          return achillesImageItem
        case CharacterNames.Apollo:
          return apolonImageItem
        case CharacterNames.Medusa:
          return medusaImageItem
        case CharacterNames.Chimera:
          return chimeraImageItem
        case CharacterNames.Titan:
          return titanImageItem
        case CharacterNames.Zeus:
          return zeusImageItem
        case CharacterNames.Atlas:
          return atlasImageItem
        case CharacterNames.Minotaur:
          return minotaurImageItem
        case CharacterNames.Chiron:
          return chironImageItem
        case CharacterNames.Cyclop:
          return cyclopImageItem
      }
      break;
    }
    case 'chests': {
      switch (item.rarity) {
        case 'normal':
          return commonBoxImageItem
        case 'uncommon':
          return uncommonBoxImageItem
        case 'rare':
          return rareBoxImageItem
        case 'legendary':
          return legendaryBoxImageItem
        case 'atlas':
          return atlasBoxImageItem
        case 'bonus':
          return bonusBoxImageItem
      }
      break
    }
    case 'evolving_stones': {
      return stoneImageItem
    }
  }
}

export const formatChest = (chest: IChest, address: string, index: number): IMarketItem => ({
  ...chest,
  id: `chest_${index}`,
  price: 0,
  oldContract: false,
  inGame: false,
  tokenId: address.toLowerCase() + '-' + index,
  owner: address.toLowerCase(),
  date: 0,
  rarity: chest.rarity === 0 ? 'normal'
    : chest.rarity === 1 ? 'uncommon'
      : chest.rarity === 2 ? 'rare'
        : chest.rarity === 3 ? 'legendary'
          : chest.rarity === 4 ? 'atlas'
            : 'bonus'
})

export const formatStone = (id: string, address: string): IMarketItem => ({
  id: `stone_${id}`,
  rarity: 'normal',
  tokenId: address.toLowerCase() + '-' + id,
  category: 'evolving_stones',
  owner: address.toLowerCase(),
  price: 0,
  oldContract: false,
  date: 0,
  inGame: false,
  name: 'Evolving Stone',
  collection: 1,
  level: 0
})

export const getImage = (item: IMarketItem) => {
  if (!item || item === null) return ''
  switch (item.category) {
    case 'characters': {
      switch (item.name) {
        case CharacterNames.Achilles:
          return achillesImage
        case CharacterNames.Apollo:
          return apolonImage
        case CharacterNames.Medusa:
          return medusaImage
        case CharacterNames.Chimera:
          return chimeraImage
        case CharacterNames.Titan:
          return titanImage
        case CharacterNames.Zeus:
          return zeusImage
        case CharacterNames.Atlas:
          return atlasImage
        case CharacterNames.Minotaur:
          return minotaurImage
        case CharacterNames.Chiron:
          return chironImage
        case CharacterNames.Cyclop:
          return cyclopImage
      }
      break;
    }
    case 'chests': {
      switch (item.rarity) {
        case 'normal':
          return commonChestImage
        case 'uncommon':
          return uncommonChestImage
        case 'rare':
          return rareChestImage
        case 'legendary':
          return legendaryChestImage
        case 'atlas':
          return atlasChestImage
        case 'bonus':
          return bonusChestImage
      }
      break
    }
    case 'evolving_stones': {
      return stoneImage
    }
  }
}

export const data: IMarketItem[] = []

export const collection: IMarketItem[] = []