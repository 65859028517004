import React, { useCallback, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import { useEffect } from 'react';
import { useUserData } from 'src/context/UserContext';
import { toast } from 'sonner';
import { getParsedEthersError } from '@enzoferey/ethers-error-parser';
import axios from 'axios';
import { useEthersSigner } from 'src/context/ethers';

import { Starter } from 'src/abis';
import { ethers } from 'ethers';
import { Button } from 'react-bootstrap';

const isValidAddress = (address: string) => {
  return address.match(/^0x[a-fA-F0-9]{40}$/) !== null;
}

const AirdropPack = () => {
  const [airdropping, setAirdropping] = useState(false);
  const signer = useEthersSigner()
  const { signature } = useUserData()
  const [address, setAddress] = useState('')
  useEffect(() => {
    document.body.className = 'main my-wallet';
    return () => {
      document.body.className = '';
    };
  }, []);

  const aidrop = useCallback(async () => {
    let errorMessage = "An unexpected error has occurred while purchasing the starter pack. Please contact us on Discord if you have paid already.";
    let tx = ''
    if (!isValidAddress(address)) {
      toast.error("Please enter a valid address")
      return
    }
    setAirdropping(true)
    try {
      const contract = new ethers.Contract(process.env.REACT_APP_STARTER_CONTRACT, Starter, signer)
      const rawTx = await (await contract.airdrop(address)).wait()
      tx = rawTx.transactionHash
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
      setAirdropping(false)
      return
    }
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/airdroppack`,
        {
          signature,
          transaction: tx,
          address
        }
      )
      toast.success("You have successfully aidropped the starter pack!")
    } catch (rawError) {
      const error = rawError as { response?: { data?: { error?: string } } }
      console.log(rawError)
      toast.error(error.response?.data?.error || errorMessage)
    }
    setAirdropping(false)
  }, [signer, signature, address])

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="">
          <div className="content-top-bar">
            <Row>
              <Col xl={9} lg={9}>
                <div className="d-flex">
                  <div className="box top-bar-box me-4 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                    <a href="https://whitepaper.olympus.game/characters" target='_blank' rel="noreferrer">
                      <p className="text-white m-0">
                        <img src="/images/character-icon.png" className="pe-3" /> NFT Characters
                      </p>
                    </a>
                  </div>
                  <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                    <a href="https://whitepaper.olympus.game/" target='_blank' rel="noreferrer">
                      <p className="text-white mb-0">
                        <img src="/images/white-paper-icon.png" className="pe-3" /> Whitepaper
                      </p>
                    </a>
                  </div>
                  <div className="box top-bar-box ms-4 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                    <a href={`https://app.1inch.io/#/56/simple/swap/BNB/${process.env.REACT_APP_OLYMP_CONTRACT}`} target='_blank' rel="noreferrer">
                      <p className="text-white mb-0">
                        <img src="/images/pancakeswap.png" className="pe-3" height={25} /> Buy OLYMP
                      </p>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Tab.Container defaultActiveKey="first">

            <div className="box margin-top-box p-3  p-xl-4  pt-xl-3 nft-tab-content" style={{ marginTop: '100px' }}>
              <p className="text-white fs-4 mb-0 top-right-text d-flex" style={{ fontWeight: 'bold' }}>
                Address
              </p>
              <input type="text" placeholder="0x90c24696B90D531519f49b909520924299C2d852" value={address} onChange={e => setAddress(e.target.value)} className=" py-2 staking-text-box airdrop-input  border-0 w-100 text-center py-1" />
              <Button className="purple-btn mt-4 token-btn w-100 py-3" onClick={aidrop} disabled={airdropping}>
                {airdropping ? 'Airdropping...' : 'Airdrop'}
              </Button>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
export default AirdropPack;
