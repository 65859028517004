import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { formatValue } from 'src/utils';
import { useUserData } from 'src/context/UserContext';
import { useAccount } from 'wagmi';
const Renting = () => {
  const { address } = useAccount()
  // add class to page
  useEffect(() => {
    document.body.className = 'main renting';
    return () => {
      document.body.className = '';
    };
  }, []);
  //   end add class to page
  const olympBalance = useUserData().createdUser.olymp

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="content-top-bar">
          <Row>
            <Col xl={9} lg={9}>
              <div className="d-flex">
                <div className="box top-bar-box me-3 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href="https://whitepaper.olympus.game/characters" target='_blank' rel="noreferrer">
                    <p className="text-white m-0">
                      <img src="/images/character-icon.png" className="pe-3" alt="" /> NFT Characters
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 me-3">
                  <a href="https://whitepaper.olympus.game/" target='_blank' rel="noreferrer">
                    <p className="text-white mb-0">
                      <img src="/images/white-paper-icon.png" className="pe-3" alt="" /> Whitepaper
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <p className="text-white mb-0">
                    <img src="/images/top-bar-third-icon.png" className="pe-3" alt="" />{formatValue(olympBalance)} OLYMP in wallet
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3}>
              <div className="box top-right-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 py-3 float-end">
                <p className="text-white mb-0 top-right-text d-flex">
                  {' '}
                  {address.slice(0, 2)}...{address.slice(-5)} <img  alt="" src="/images/circle-icon.png" className="" />
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="renting-rigth-main text-center position-relative mt-3">
          <h1 className="play-game-large-heading mb-0">Play</h1>
          <p className="play-game-small-heading">olympus game</p>
          <p className="play-game-sub-text mt-4">And earn while having fun!</p>
          <Button className="dark-yellow-btn mt-3 mt-xl-5 mt-lg-5 mt-md-5">Start now</Button>
          <img src="/images/hamer.png" className="position-absolute renting-top-icon-one" alt="" />
          <img src="/images/eagle-icon.png" className="position-absolute renting-top-icon-two" alt="" />
        </div>

        <div className="renting-text-area">
          <h1 className="main-heading color-purple text-center">lorem ipsum</h1>
          <div className="box">
            <p className="font-20-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Renting;
