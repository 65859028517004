import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, Outlet } from "react-router-dom";
const Mobilenavigation = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // to add active class
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(
    () => {
      setUrl(location.pathname);
    },
    [location]
  );
  //  end   to add active class
  return (
    <>
      <div className="mobile-nav pt-3 pb-3">
        <Row>
          <Col xl={4} xs={2} />
          <Col xl={4} xs={8} className="text-center">
            <img src="/images/logo.png" className="mobile-logo" />
          </Col>
          <Col xl={4} xs={2} className="p-0 my-auto">
            <Button onClick={handleShow} className="float-end box mobile-toggle-btn" style={{ display: show ? "none" : "block" }}>
              <img src="/images/mobile-toggle-icon.png" />
            </Button>
            <Button onClick={handleClose} className="float-end box mobile-close-btn" style={{ display: show ? "block" : "none" }}>
              <img src="/images/X-close-menu.svg" width="20x" />
            </Button>
            <Modal show={show} onHide={handleClose} className="nav-modal">
              <Modal.Body>
                <div className="box  pb-3 pt-3">
                  <Navbar expand="lg" className="mobile-nav-bar">
                    <Nav className="w-100 flex-column" as="ul">
                      <Nav.Item as="li">
                        <Nav.Link as={Link} to="/" className={"" + (url === "/" ? " active" : "")} onClick={handleClose}>
                          <img src="/images/menu-icon1-Home.png" className="" width="29px" height="29px" />
                          <span className="ps-3">Home</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link as={Link} to="/nftwallet" className={"" + (url === "/nftwallet" ? " active" : "")} onClick={handleClose}>
                          <img src="/images/men-icon2- My-NFTs.png" className="" width="29px" height="29px" />
                          <span className="ps-3">My NFTs</span>
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item as="li">
                        <Nav.Link as={Link} to="/marketplace" className={"" + (url === "/marketplace" ? " active" : "")} onClick={handleClose}>
                          <img src="/images/menu-icon3-Marketplace.png" className="" width="29px" height="29px" />
                          <span className="ps-3">Marketplace</span>
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item as="li">
                        <Nav.Link as={Link} to="/play" className={"" + (url === "/play" ? " active" : "")} onClick={handleClose}>
                          <img src="/images/menu-icon4-Play.png" className="" width="29px" height="29px" />
                          <span className="ps-3">Play</span>
                        </Nav.Link>
                      </Nav.Item>
{/*                       <Nav.Item as="li">
                        <Nav.Link as={Link} to="/renting" className={"" + (url === "/renting" ? " active" : "")} onClick={handleClose}>
                          <img src="/images/menu-icon5-NFT Renting.png" className="" width="29px" height="29px" />
                          <span className="ps-3">NFT Renting</span>
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item as="li">
                        <Nav.Link as={Link} to="/token" className={"" + (url === "/token" ? " active" : "")} onClick={handleClose}>
                          <img src="/images/menuicon6-My-Tokens.png" className="" width="29px" height="29px" />
                          <span className="ps-3">My Tokens</span>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link as={Link} to="/vote" className={"" + (url === "/vote" ? " active" : "")} onClick={handleClose}>
                          <img src="/images/menu-icon7-Vote.png" className="" width="29px" height="29px" />
                          <span className="ps-3">Vote</span>
                        </Nav.Link>
                      </Nav.Item>
{/*                       <Nav.Item as="li">
                        <Nav.Link as={Link} to="/staking" className={"" + (url === "/staking" ? " active" : "")} onClick={handleClose}>
                          <img src="/images/menu-icon8-Staking.png" width="29px" height="29px" />
                          <span className="ps-3">Staking</span>
                        </Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                  </Navbar>
                </div>
                <div className="box mt-4">
                  <div className="sidebar-social-div">
                    <img src="/images/discord-icon.svg" width="30px" height="30px" />
                    <img src="/images/telegram-icon.svg" width="30px" height="30px" />
                    <img src="/images/twitter-icon.svg" width="30px" height="30px" className="me-0" />
                  </div>
                </div>
                <div className="box mt-4">
                  <Nav.Link as={Link} to="/" onClick={handleClose} style={{ paddingTop: "18px", paddingBottom: "18px" }}>
                    <img src="/images//menu-icon-disconnect.png" width="29px" /> <span className="ps-3">Disconnect</span>
                  </Nav.Link>
                </div>
              </Modal.Body>
              <img src="/images/token-icon.png" className="mobile-nav-token" />
              <img src="/images/axe-icon.png" className="mobile-nav-axe" />
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Mobilenavigation;
