import { useState, useEffect, useCallback, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import { useUserData } from 'src/context/UserContext';
import { formatValue } from 'src/utils';
import { ethers } from 'ethers';
import { ERC20, OlympBridge } from 'src/abis';
import { getParsedEthersError } from '@enzoferey/ethers-error-parser';
import { toast } from 'sonner';
import axios from 'axios';
import Vesting from './Vesting';
import PurchaseOlymp from './PurchaseOlymp';
import { useEthersSigner } from 'src/context/ethers';
import { useAccount } from 'wagmi';
const Token = () => {
  const [approving, setApproving] = useState(false)
  const [depositing, setDepositing] = useState(false)
  const { setCreatedUser, signature, createdUser, vestingData } = useUserData()
  const [requestingWithdraw, setRequestingWithdraw] = useState(false)
  const [withdrawing, setWithdrawing] = useState(false)
  const { address } = useAccount()
  const signer = useEthersSigner()
  // add class to page
  useEffect(() => {
    document.body.className = 'main token';
    return () => {
      document.body.className = '';
    };
  }, []);
  const { olymp, approvedOlympBridge, inGameBalance } = useUserData().createdUser
  const [olyamount, setOlyAmount] = useState('');
  const handleAmount = e => {
    setOlyAmount(e.target.value);
  };

  const unvestedIcoTokens = vestingData.unvestedAlpha + vestingData.unvestedBeta
  const vestedIcoTokens = vestingData.vestedAlpha + vestingData.vestedBeta
  const requiresAction = unvestedIcoTokens > 0 && vestedIcoTokens === 0

  const approve = useCallback(async () => {
    const olymp = process.env.REACT_APP_OLYMP_CONTRACT
    const busd = new ethers.Contract(olymp, ERC20, signer)
    setApproving(true)
    try {
      await (await busd.approve(process.env.REACT_APP_OLYMP_BRIDGE_CONTRACT, ethers.constants.MaxUint256)).wait()
      setCreatedUser(createdUser => ({ ...createdUser, approvedOlympBridge: true }))
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while approving. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setApproving(false)
  }, [signer, setCreatedUser])

  const deposit = useCallback(async () => {
    if (!createdUser.registered) {
      toast.error('You need to register first. Please on go the "play" page to proceed.')
      return
    }
    const bridgeAddress = process.env.REACT_APP_OLYMP_BRIDGE_CONTRACT
    const bridgeContract = new ethers.Contract(bridgeAddress, OlympBridge, signer)
    setDepositing(true)
    try {
      await (await bridgeContract.sendInGame(ethers.utils.parseEther(olyamount))).wait()
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while sending tokens. Please try again.";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
      setDepositing(false)
      return
    }

    try {
      toast.success('Olymp successfully sent in game.')
      setCreatedUser(createdUser => ({
        ...createdUser,
        olymp: createdUser.olymp - parseFloat(olyamount),
        inGameBalance: createdUser.inGameBalance + Number(olyamount),
      }))
    } catch (rawError) {
      const errorMessage = 'An unexpected error has occurred while sending Olymp in game'
      const error = rawError as { response?: { data?: { error?: string } } }
      console.log(rawError)
      toast.error((error.response?.data?.error || errorMessage) + '. Please contact us on Discord.')
    }
    setDepositing(false)
  }, [signer, setCreatedUser, olyamount, createdUser.registered])

  const requestWithdraw = useCallback(async () => {
    const bridgeAddress = process.env.REACT_APP_OLYMP_BRIDGE_CONTRACT
    const bridgeContract = new ethers.Contract(bridgeAddress, OlympBridge, signer)
    setRequestingWithdraw(true)
    try {
      await (await bridgeContract.sendFromGame(ethers.utils.parseEther(olyamount))).wait()
      setCreatedUser(createdUser => ({
        ...createdUser,
        inGameBalance: createdUser.inGameBalance - Number(olyamount),
        withdrawAmount: createdUser.withdrawAmount + Number(olyamount),
        claimingAvailable: new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)
      }))
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while submitting withdrawal request. Please try again.";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
      setRequestingWithdraw(false)
      return
    }
    setRequestingWithdraw(false)
  }, [signer, setCreatedUser, olyamount])

  const withdraw = useCallback(async () => {
    let tx = ''
    const bridgeAddress = process.env.REACT_APP_OLYMP_BRIDGE_CONTRACT
    const bridgeContract = new ethers.Contract(bridgeAddress, OlympBridge, signer)
    setWithdrawing(true)
    try {
      const rawTx = await (await bridgeContract.withdraw()).wait()
      tx = rawTx.transactionHash
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while withdrawing. Please try again.";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
      setWithdrawing(false)
      return
    }

    try {
      await axios.post(process.env.REACT_APP_API + '/api/withdraw', {
        transaction: tx,
        signature,
        address
      })
      toast.success('Olymp successfully withdrew.')
      setCreatedUser(createdUser => ({
        ...createdUser,
        withdrawAmount: 0,
        claimingAvailable: new Date()
      }))
    } catch (rawError) {
      const errorMessage = 'An unexpected error has occurred while withdraing OLYMP'
      const error = rawError as { response?: { data?: { error?: string } } }
      console.log(rawError)
      toast.error((error.response?.data?.error || errorMessage) + '. Please contact us on Discord.')
    }
    setWithdrawing(false)
  }, [signer, setCreatedUser, signature, address])

  const canClaim = createdUser.claimingAvailable.getTime() < Date.now()
  const { days, hours, minutes } = useMemo(() => {
    const date = createdUser.claimingAvailable
    const time = date.getTime() - Date.now();
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor(((time % (1000 * 60 * 60 * 24)) % (1000 * 60 * 60)) / (1000 * 60));
    return { days, hours, minutes };
  }, [createdUser.claimingAvailable])

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="content-top-bar">
          <Row>
            <Col xl={9} lg={9}>
              <div className="d-flex">
                <div className="box top-bar-box me-3 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href="https://whitepaper.olympus.game/characters" target='_blank' rel="noreferrer">
                    <p className="text-white m-0">
                      <img src="/images/character-icon.png" className="pe-3" /> NFT Characters
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href="https://whitepaper.olympus.game/" target='_blank' rel="noreferrer">
                    <p className="text-white mb-0">
                      <img src="/images/white-paper-icon.png" className="pe-3" /> Whitepaper
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box ms-4 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href={`https://app.1inch.io/#/56/simple/swap/BNB/${process.env.REACT_APP_OLYMP_CONTRACT}`} target='_blank' rel="noreferrer">
                    <p className="text-white mb-0">
                      <img src="/images/pancakeswap.png" className="pe-3" height={25} /> Buy OLYMP
                    </p>
                  </a>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3}>
              <div className="box top-right-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 py-3 float-end">
                <p className="text-white mb-0 top-right-text d-flex">
                  {' '}
                  {address.slice(0, 2)}...{address.slice(-5)} <img src="/images/circle-icon.png" className="" />
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="mt-4">
          <Tab.Container id="left-tabs-example" defaultActiveKey="wallet-token">
            <Row>
              <Col xl={12}>
                <Nav variant="pills" className="token-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="wallet-token" className="tab-title">
                      Wallet Tokens<br />
                      <span className="token-tabtitle-number">{formatValue(olymp)}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="game-token" className="tab-title">
                      In-game Tokens<br />
                      <span className="token-tabtitle-number">{formatValue(inGameBalance)}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="vesting" className="tab-title">
                      Vesting<br />
                      <span className="token-tabtitle-number">{requiresAction ? '⚠️ Action required' : formatValue(vestedIcoTokens + unvestedIcoTokens)}</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <div className="tab-body-area mt-5">
              <Tab.Content>
                <Tab.Pane eventKey="wallet-token">
                  <Row>
                    <Col xl={6}>
                      <div>
                        <h1 className="main-heading color-yellow text-capitalize font-60 text-start ps-0 ps-xl-5 ps-lg-5 ps-md-5">Send in-game</h1>
                        <div className="box p-5 token-tab-content-box">
                          <div className="d-flex">
                            <p className="font-24-700 text-white mb-0 mb-xl-4 mb-lg-4 mb-md-4">Wallet Tokens</p>
                            <p className="font-24-700 text-white text-end flex-grow-1  mb-0 mb-xl-4 mb-lg-4 mb-md-4">{formatValue(olymp)}</p>
                          </div>
                          <img src="/images/yellow-line-divider.png" className="w-100" />

                          <p className="font-24-700 text-white mt-4">Send Tokens In-game</p>
                          <div className="token-width-291" style={{ width: '291px' }}>
                            <p className="font-16-500 text-white mt-4 text-start">Type in the amount you want to send to your game account:</p>
                            <Form className="token-box-form">
                              <Form.Group className="" controlId="formBasicEmail">
                                <Form.Control onChange={handleAmount} value={olyamount} type="email" placeholder="Amount in OLYMP" className="input text-start p-4" />
                              </Form.Group>
                            </Form>
                            <Button
                              className="yellow-btn mt-4 token-btn"
                              disabled={depositing || approving}
                              onClick={approvedOlympBridge ? deposit : approve}
                            >
                              {
                                approving ? 'Approving...' : depositing ? 'Sending...' : approvedOlympBridge ? 'Send' : 'Approve'
                              }
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <PurchaseOlymp />
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="game-token">
                  <Row>
                    <Col xl={6}>
                      <div>
                        <h1 className="main-heading color-yellow text-capitalize font-60 text-start ps-0 ps-xl-5 ps-lg-5 ps-md-5">Send to wallet</h1>
                        <div className="box p-5 token-tab-content-box">
                          <div className="d-flex">
                            <p className="font-24-700 text-white mb-0 mb-xl-4 mb-lg-4 mb-md-4">In-game Tokens</p>
                            <p className="font-24-700 text-white text-end flex-grow-1  mb-0 mb-xl-4 mb-lg-4 mb-md-4">{formatValue(inGameBalance)}</p>
                          </div>
                          <img src="/images/yellow-line-divider.png" className="w-100" />
                          <div className="d-flex align-items-start mt-4 position-relative mb-3">
                            <div className="box-dark-content">
                              Please make sure to <span className="text-bold fw-bold">always</span> have an in-game balance that's equal to or greater than the amount you're sending during the 72h waiting period to avoid getting your transfer cancelled.
                            </div>
                          </div>

                          <p className="font-24-700 text-white mt-4 mb-0"> {canClaim ? 'Send Tokens to your Wallet' : 'Claiming available after:'}</p>
                          <p className="font-24-700 text-dark-yellow mt-0 mb-0" style={{ display: !approve ? 'none' : 'block' }}>
                            {
                              canClaim ? 'Claimable' : `${days} days, ${hours} hours, ${minutes} min`
                            }
                          </p>
                          <div className="token-width-291" style={{ width: '291px' }}>
                            <p className="font-16-500 text-white mt-4 text-start">
                              {createdUser.withdrawAmount ? `You have ${formatValue(createdUser.withdrawAmount)} OLYMP being transferred from the game.` : ' A 3-day waiting period will begin after you approve the transaction.'}
                            </p>

                            {
                              createdUser.withdrawAmount === 0 && (
                                <Form className="token-box-form">
                                  <Form.Group className="" controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Amount in OLYMP" className="input text-start p-4" value={olyamount} onChange={handleAmount} />
                                  </Form.Group>
                                </Form>
                              )
                            }

                            {
                              createdUser.withdrawAmount > 0 && canClaim ? (
                                <Button className={'yellow-btn mt-4 token-btn'} disabled={withdrawing} onClick={withdraw}>
                                  {withdrawing ? 'Withdrawing...' : 'Withdraw'}
                                </Button>
                              ) : createdUser.withdrawAmount === 0 ? (
                                <Button className={'yellow-btn mt-4 token-btn'} disabled={requestingWithdraw} onClick={requestWithdraw}>
                                  {requestingWithdraw ? 'Requesting...' : 'Request'}
                                </Button>
                              ) : (<></>)
                            }

                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mt-5 mt-xl-0">
                        <h1 className="main-heading color-purple text-capitalize font-60 text-start ps-0 ps-xl-5 ps-lg-5 ps-md-5">Buy OLYMP</h1>
                        <div className="box p-5 token-tab-content-box">
                          <p className="font-20-500 text-white mb-1">Purchase OLYMP by bank card and have it sent to your game account.</p>

                          <img src="/images/line-divider.png" className="w-100" />

                          <p className="font-24-700 text-white mt-4">Purchase OLYMP</p>
                          <div className="token-width-291" style={{ width: '291px' }}>
                            <Form className="token-box-form">
                              <Form.Group className="position-relative">
                                <Form.Control type="text" onChange={handleAmount} value={olyamount} placeholder="Amount in OLYMP" className=" input text-start p-4" />
                                <span className="position-absolute  token-filed-right-staic-text" style={{ display: olyamount.trim().length !== 0 ? 'block' : 'none' }}>
                                  OLYMP
                                </span>
                              </Form.Group>

                              <Form.Group className="mt-3 position-relative">
                                <Form.Control type="text" value={olyamount ? (Number(olyamount) * 0.05).toFixed(2) : ''} placeholder="Amount in EUR" className="input text-start p-4" />
                                <span className="position-absolute  token-filed-right-staic-text" style={{ display: olyamount.trim().length !== 0 ? 'block' : 'none' }}>
                                  EUR
                                </span>
                              </Form.Group>
                            </Form>
                            <Button className="purple-btn mt-4 token-btn">Buy Now</Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Vesting />
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
export default Token;
