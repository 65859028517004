import React from "react";

interface ILoaderProps {
  fullScreen?: boolean;
  size: string;
}

export const Loader: React.FC<ILoaderProps> = ({ fullScreen = false, size }) => {

  return (
    <div className="swicth-network-main main switch" id="loader-content">
      <img src="/images/logo.png" className="logo mx-auto d-flex pt-5" />
      <div className="switch-main-box">
        <div className="box switch-box loader-box">
          <div className="loader">
            <div className={fullScreen ? 'loader__fullscreen' : ''}>
              <svg className="spinner" width={size} height={size} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth={6} strokeLinecap="round" cx={33} cy={33} r={30} />
              </svg>
            </div>
          </div>
          <p className="text-white font-24-700 mt-4 mb-0" >Loading Data...</p>
        </div>
      </div>
    </div>
  )
}