import { useEffect } from 'react';
import { useUserData } from 'src/context/UserContext';
import { useEthersSigner } from 'src/context/ethers';
import { useAccount } from 'wagmi';
const Auth = () => {
  const { address } = useAccount()
  const { signMessage } = useUserData()
  const signer = useEthersSigner()
  // add class to page
  useEffect(() => {
    document.body.className = 'main switch';
    return () => {
      document.body.className = '';
    };
  }, []);
  //   end add class to page
  return (
    <>
      <div className="swicth-network-main">
        <img src="/images/logo.png" className="logo mx-auto d-flex pt-5" />
        <div className="switch-main-box">
          <div className="box switch-box">
            <p className="text-white font-24-700">Authenticate yourself to proceed.</p>
            <img src="/images/line-divider.png" className="mt-3 mb-3 w-100" />
            <button className="blue-btn mt-3 mt-xl-5 mt-lg-5 mt-md-5  text-white"
              onClick={() => signMessage(address, signer)}>
              Authenticate Myself
            </button>
            <img src="/images/switch-zig-one.png" className="switch-zig-one" />
            <img src="/images/switch-zig-two.png" className="switch-zig-two" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Auth;
