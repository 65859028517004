import React, { useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useUserData } from 'src/context/UserContext';
import { formatValue } from 'src/utils';
import { ERC20, Starter } from 'src/abis';
import { ethers } from 'ethers';
import { toast } from 'sonner';
import { getParsedEthersError } from '@enzoferey/ethers-error-parser';
import axios from 'axios';
import Profits from './Profits';
import Register from './Register';
import OpenGame from './OpenGame';
import { useAccount } from 'wagmi';
import { useEthersProvider, useEthersSigner } from 'src/context/ethers';
const Play = () => {
  const { address } = useAccount()
  const signer = useEthersSigner()
  const provider = useEthersProvider()
  const { createdUser, setCreatedUser } = useUserData()
  const { signature } = useUserData()
  const [purchasing, setPurchasing] = useState(false)
  const [approving, setApproving] = useState(false)
  const approved = createdUser.approvedStarter
  const [price, setPrice] = useState(-1)

  const fetchPrice = useCallback(async () => {
    const contract = new ethers.Contract(process.env.REACT_APP_STARTER_CONTRACT, Starter, provider)
    const rawPrice = await contract.packPrice()
    const price = ethers.utils.formatEther(rawPrice)
    setPrice(parseFloat(price))
  }, [provider])

  useEffect(() => {
    fetchPrice()
  }, [signer, fetchPrice])

  const approve = useCallback(async () => {
    const olymp = process.env.REACT_APP_OLYMP_CONTRACT
    const busd = new ethers.Contract(olymp, ERC20, signer)
    setApproving(true)
    try {
      await (await busd.approve(process.env.REACT_APP_STARTER_CONTRACT, ethers.constants.MaxUint256)).wait()
      setCreatedUser(createdUser => ({ ...createdUser, approvedStarter: true }))
      toast.success("You have successfully approved. You can now purchase the starter pack.")
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while approving. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setApproving(false)
    return
  }, [signer, setCreatedUser])

  const purchase = useCallback(async () => {
    let errorMessage = "An unexpected error has occurred while purchasing the starter pack. Please contact us on Discord if you have paid already.";
    let tx = ''
    setPurchasing(true)
    try {
      const contract = new ethers.Contract(process.env.REACT_APP_STARTER_CONTRACT, Starter, signer)
      const rawTx = await (await contract.purchase()).wait()
      tx = rawTx.transactionHash
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
      setPurchasing(false)
      return
    }
    try {
      await axios.post(`${process.env.REACT_APP_API}/api/purchase`,
        {
          signature,
          transaction: tx,
          address
        }
      )
      setCreatedUser({
        ...createdUser,
        paid: true,
        purchasedStarter: true
      })
      toast.success("You have successfully purchased the starter pack!")
    } catch (rawError) {
      const error = rawError as { response?: { data?: { error?: string } } }
      console.log(rawError)
      toast.error(error.response?.data?.error || errorMessage)
    }
    setPurchasing(false)
  }, [signer, signature, address, createdUser, setCreatedUser])

  // add class to page
  useEffect(() => {
    document.body.className = 'main play';
    return () => {
      document.body.className = '';
    };
  }, []);
  // change password
  const olympBalance = useUserData().createdUser.olymp

  return (
    <>
      <div className="ps-0 ps-xl-4 ps-lg-4">
        <div className="content-top-bar">
          <Row>
            <Col xl={9} lg={9}>
              <div className="d-flex">
                <div className="box top-bar-box me-3 px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <a href="https://whitepaper.olympus.game/characters" target='_blank' rel="noreferrer">
                    <p className="text-white m-0">
                      <img src="/images/character-icon.png" className="pe-3" /> NFT Characters
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 me-3">
                  <a href="https://whitepaper.olympus.game/" target='_blank' rel="noreferrer">
                    <p className="text-white mb-0">
                      <img src="/images/white-paper-icon.png" className="pe-3" /> Whitepaper
                    </p>
                  </a>
                </div>
                <div className="box top-bar-box px-5 px-xl-5 px-lg-3 px-md-3 py-3">
                  <p className="text-white mb-0">
                    <img src="/images/top-bar-third-icon.png" className="pe-3" />{formatValue(olympBalance)} OLYMP in wallet
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3}>
              <div className="box top-right-box px-5 px-xl-5 px-lg-3 px-md-3 py-3 py-3 float-end">
                <p className="text-white mb-0 top-right-text d-flex">
                  {' '}
                  {address.slice(0, 2)}...{address.slice(-5)} <img src="/images/circle-icon.png" className="" />
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="play-starter-pack mt-4" style={{ display: createdUser.paid ? 'none' : 'block' }}>
          <div className="play-starter-pack-inner">
            <Row>
              <Col xl={6} lg={12} md={12} col={12} className="play-starter-left-col-main">
                <div className="play-starter-left-col">
                  <div className="play-dektop-title">
                    <span>Get the</span>
                    <h1>
                      Starter pack <span>and</span>
                    </h1>
                    <span>start playing!</span>
                    <br />
                    {
                      price > -1 && (
                        <Button className="dark-yellow-btn mt-4" disabled={purchasing || approving} onClick={approved ? purchase : approve}>
                          {approving ? 'Approving...' : purchasing ? 'Purchasing...' : `Get now for ${price} $OLYMP`}
                        </Button>
                      )
                    }
                  </div>
                  <div className="play-mobile-title d-none">
                    <h2 className="font-20-400">get the olympus game</h2>
                    <h1 className="font-40-400">starter pack</h1>
                  </div>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12} col={12} className="my-auto">
                <div className="play-starter-right-col">
                  <Row>
                    <Col xl={6} lg={6} md={6} xs={6} className="play-starter-right-col1">
                      <div className="starter-play-card">
                        <h4 className="starter-paly-card-title text-center mb-0">NFT cards</h4>
                        <div className="starter-play-card-inner">
                          <img src="/images/nft-card.png" className="w-100" />
                          <h4 className="starter-paly-card-bottom-text text-center">x8</h4>
                        </div>
                      </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} xs={6} className="play-starter-right-col2">
                      <div className="starter-play-card">
                        <h4 className="starter-paly-card-title text-center">$OLYMP</h4>
                        <div className="starter-play-card-inner">
                          <img src="/images/stack-of-coins.png" className="w-100" />
                          <h4 className="starter-paly-card-bottom-text text-center">x100</h4>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="play-mobile-title d-none text-center">
                  {
                    price > -1 && (
                      <Button className="dark-yellow-btn mt-4" disabled={purchasing || approving} onClick={approved ? purchase : approve}>
                        {approving ? 'Approving...' : purchasing ? 'Purchasing...' : `Get now for ${price} $OLYMP`}
                      </Button>
                    )
                  }
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Register />

        <Profits />

        <OpenGame />
      </div>
    </>
  );
};
export default Play;
