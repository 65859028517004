import { getParsedEthersError } from '@enzoferey/ethers-error-parser'
import { ethers } from 'ethers'
import { toast } from 'sonner'
import React, { useCallback } from 'react'
import { ERC20, Marketplace } from 'src/abis'
import { useCharactersData } from 'src/context/CharactersContext'
import { useMarketplaceContext } from 'src/context/MarketplaceContext'
import { useUserData } from 'src/context/UserContext'
import { useEthersSigner } from 'src/context/ethers'
import { IMarketItem } from 'src/interfaces'
import { useAccount } from 'wagmi'

interface Props {
  buttonClasses: string
  item: IMarketItem
}
const Buy: React.FC<Props> = ({ buttonClasses, item }) => {
  const { address } = useAccount()
  const signer = useEthersSigner()
  const [buying, setBuying] = React.useState(false)
  const [approving, setApproving] = React.useState(false)

  const { fetchListings } = useMarketplaceContext()
  const { fetchCharacters } = useCharactersData()
  const { fetchUserData, setCreatedUser, createdUser } = useUserData()

  const reloadData = useCallback(() => {
    fetchUserData(address)
    fetchCharacters(address)
    fetchListings()
  }, [fetchCharacters, fetchListings, fetchUserData, address])

  const hasApprovedForBuy = item === null ? false : (item.oldContract ? createdUser.approvedBusdOldMarketplace : createdUser.approvedBusdMarketplace)

  const handleBuy = useCallback(async () => {
    const marketplaceAddress = item.oldContract ? process.env.REACT_APP_OLD_MARKETPLACE_CONTRACT : process.env.REACT_APP_MARKETPLACE_CONTRACT
    const marketplace = new ethers.Contract(marketplaceAddress, Marketplace, signer)
    const actualId = item.id.includes('-') ? item.id.split('-')[1] : item.id
    setBuying(true)
    try {
      const method = item.category === 'characters' ? marketplace.buyERC721(actualId) : item.category === 'chests' ? marketplace.buyERC1155(actualId) : marketplace.buyERC20(actualId)
      await (await method).wait()
      toast.success('Successfully bought item!')
      reloadData()
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while buying item. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setBuying(false)
  }, [item, signer, reloadData])

  const handleApprove = useCallback(async () => {
    const marketplaceAddress = item.oldContract ? process.env.REACT_APP_OLD_MARKETPLACE_CONTRACT : process.env.REACT_APP_MARKETPLACE_CONTRACT
    const contractAddress = process.env.REACT_APP_BUSD_CONTRACT
    const abi = ERC20
    const contract = new ethers.Contract(contractAddress, abi, signer)
    setApproving(true)
    try {
      const method = contract.approve(marketplaceAddress, '10000000000000000000000')
      await (await method).wait()

      setCreatedUser(createdUser => ({
        ...createdUser,
        approvedBusdMarketplace: !item.oldContract ? true : createdUser.approvedBusdMarketplace,
        approvedBusdOldMarketplace: item.oldContract ? true : createdUser.approvedBusdOldMarketplace
      }))
      toast.success('Successfully approved. You can now buy items.')
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while approving. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setApproving(false)
  }, [item, signer, setCreatedUser])

  return (
    <button
      disabled={approving || buying}
      onClick={() => hasApprovedForBuy ? handleBuy() : handleApprove()}
      className={`${buttonClasses} yellow-btn-two  text-white  mt-3 me-0 justify-content-center  mx-auto text-uppercase`}
    >
      {
        buying ? 'Buying...' : approving ? 'Approving' : 'Buy now'
      }
    </button>
  )
}

export default Buy
