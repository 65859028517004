export const getBorder = (rarity: string) => {
  switch (rarity.toLowerCase()) {
    case 'common':
    case 'normal':
      return 'yellow'
    case 'uncommon':
      return 'green-purple'
    case 'rare':
      return 'last'
    case 'epic':
    case 'gold':
    case 'diamond':
    case 'atlas':
    case 'bonuis':
      return 'purple'
    case 'legendary':
      return 'lavender'
    default:
      return ''
  }
}

export const getBackground = (rarity: string) => {
  switch (rarity.toLowerCase()) {
    case 'common':
    case 'normal':
      return 'purple-gradient-img'
    case 'uncommon':
      return 'purple-green-gradient-img'
    case 'rare':
      return 'yellow-gradient-img'
    case 'epic':
    case 'gold':
    case 'diamond':
    case 'atlas':
    case 'bonus':
      return 'nft-last-gradient-img'
    case 'legendary':
      return 'lavendar-gradient-img'
    default:
      return ''
  }
}

export const getTextColor = (rarity: string) => {
  switch (rarity.toLowerCase()) {
    case 'common':
    case 'normal':
      return 'text-white'
    case 'uncommon':
      return 'text-purple'
    case 'rare':
      return 'text-purple2'
    case 'epic':
    case 'gold':
    case 'atlas':
    case 'diamond':
    case 'bonuis':
      return 'text-green'
    case 'legendary':
      return 'text-dark-yellow'
    default:
      return ''
  }
}

export const formatDate = (date: Date, showTime: boolean) => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const formattedNumber = (number: number) => number > 9 ? number : '0' + number
  return `${formattedNumber(day)}/${formattedNumber(month)}/${formattedNumber(year)} ${showTime ? `${formattedNumber(hours)}:${formattedNumber(minutes)}:${formattedNumber(seconds)}` : ''}`
}