import React from 'react';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { useUserData } from 'src/context/UserContext';
const OpenGame = () => {
  const { createdUser } = useUserData()


  // add class to page
  useEffect(() => {
    document.body.className = 'main play';
    return () => {
      document.body.className = '';
    };
  }, []);
  return (
    <>

      <div className="play-starter-pack mt-4 text-center" style={{ display: createdUser.registered ? 'block' : 'none' }}>
        <div className="play-starter-second-box  position-relative">
          <h1 className="play-game-large-heading mb-0">Play</h1>
          <p className="play-game-small-heading">olympus game</p>
          <p className="play-game-sub-text mt-4">And earn while having fun!</p>
          <a href="https://play.olympus.game" target="_blank" style={{ color: 'white', textDecoration: 'none' }}>
            <Button className="dark-yellow-btn mt-3 mt-xl-5 mt-lg-5 mt-md-5">
              Open Olympus Game
            </Button>
          </a>

          <img src="/images/hermes-mockup.png" className="play-starter-second-box-img1" />
          <img src="/images/hermes-mockup2.png" className="play-starter-second-box-img2" />
          <img src="/images/play-secon-box-bg-img-mobile.png" className="play-starter-second-box-img-mobile d-none" />
        </div>
      </div>
    </>
  );
};
export default OpenGame;
