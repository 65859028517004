import React from 'react'
import { Row, Col, Tab, Modal } from 'react-bootstrap'
import { IMarketItem } from 'src/interfaces'
import SellItem from './SellItem'
import { useMarketplaceContext } from 'src/context/MarketplaceContext'
import MarketplaceItem from './Item'

const UserItems = () => {
  const [sellingItem, setSellingItem] = React.useState<null | IMarketItem>(null)
  const { myCollection } = useMarketplaceContext()

  return (
    <Tab.Pane eventKey="second" className="my-collection-tab-content">
      <Row className="mt-4">
        <Col xl={7} lg={6} md={6} className="d-noncce d-xl-block d-lg-block d-md-block nft-wallet-main-title">
          <h1 className="main-heading color-purple main-heading-space-left">{myCollection.length} items</h1>
        </Col>
      </Row>

      <Modal show={sellingItem !== null} onHide={() => setSellingItem(null)} backdrop="static" keyboard={false} className="item-modal">
        <Modal.Header closeButton className="border-0 p-0" />
        <Modal.Body className="item-modal-body sell-modal-body">
          {
            sellingItem !== null && (
              <SellItem selectedItem={sellingItem} />
            )
          }
        </Modal.Body>
      </Modal>

      <div className="box margin-top-box p-3  p-xl-4  pt-xl-3 nft-tab-content">
        <div className="">
          <Row className="">
            {
              myCollection.map((item, index) => (
                <MarketplaceItem key={index} item={item} onClick={() => setSellingItem(item)} />
              ))
            }
          </Row>
        </div>
      </div>
    </Tab.Pane>
  )
}
export default UserItems