import React from 'react';
import { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
const Sortby = () => {
  const [sortopen, setSortOpen] = useState(false);
  const [low, setlow] = useState('Lowest Rarity');
  const handlelow = e => {
    setlow(e.currentTarget.textContent);
    setSortOpen(!sortopen);
  };
  return (
    <>
      <span className="sort-by">Sort By:</span>
      <div className="d-inline position-relative">
        <Button
          onClick={() => setSortOpen(!sortopen)}
          aria-controls="example-collapse-text"
          aria-expanded={sortopen}
          className="purple-btn collapse-btn"
        >
          {low}
        </Button>
        <Collapse in={sortopen}>
          <div id="example-collapse-text" className="position-absolute sort-collapse-div">
            <div>
              <p className="text-white" onClick={handlelow}>
                Lowest Rarity
              </p>
              <p className="text-white" onClick={handlelow}>
                Highest Rarity
              </p>
              <p className="text-white" onClick={handlelow}>
                Lowest Level
              </p>
              <p className="text-white" onClick={handlelow}>
                Highest Level
              </p>
              <p className="text-white" onClick={handlelow}>
                By Arena
              </p>
              <p className="text-white" onClick={handlelow}>
                Lowest Price
              </p>
              <p className="text-white" onClick={handlelow}>
                Highest Price
              </p>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};
export default Sortby;
