import { getParsedEthersError } from '@enzoferey/ethers-error-parser'
import { ethers } from 'ethers'
import { toast } from 'sonner'
import React, { useCallback } from 'react'
import { Marketplace } from 'src/abis'
import { useCharactersData } from 'src/context/CharactersContext'
import { useMarketplaceContext } from 'src/context/MarketplaceContext'
import { useUserData } from 'src/context/UserContext'
import { useEthersSigner } from 'src/context/ethers'
import { IMarketItem } from 'src/interfaces'
import { useAccount } from 'wagmi'

interface Props {
  buttonClasses: string
  item: IMarketItem
}
const Unlist: React.FC<Props> = ({ buttonClasses, item }) => {
  const { address } = useAccount()
  const signer = useEthersSigner()
  const [cancelling, setCancelling] = React.useState(false)

  const { fetchListings } = useMarketplaceContext()
  const { fetchCharacters } = useCharactersData()
  const { fetchUserData } = useUserData()

  const reloadData = useCallback(() => {
    fetchUserData(address)
    fetchCharacters(address)
    fetchListings()
  }, [fetchCharacters, fetchListings, fetchUserData, address])

  const handleCancel = useCallback(async () => {
    const marketplaceAddress = item.oldContract ? process.env.REACT_APP_OLD_MARKETPLACE_CONTRACT : process.env.REACT_APP_MARKETPLACE_CONTRACT
    const marketplace = new ethers.Contract(marketplaceAddress, Marketplace, signer)
    setCancelling(true)
    const actualId = item.id.includes('-') ? item.id.split('-')[1] : item.id
    try {
      const method = item.category === 'characters' ? marketplace.cancelERC721(actualId) : item.category === 'chests' ? marketplace.cancelERC1155(actualId) : marketplace.cancelERC20(actualId)
      await (await method).wait()
      toast.success('Successfully removed listing.')
      reloadData()
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while removing the listing. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setCancelling(false)
  }, [item, signer, reloadData])

  return (
    <button className={`${buttonClasses} yellow-btn-two  text-white  mt-3 me-0 justify-content-center  mx-auto text-uppercase`} disabled={cancelling}
      onClick={() => handleCancel()}>
      {cancelling ? 'Unlisting...' : 'Unlist'}
    </button>
  )
}

export default Unlist
