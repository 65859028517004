import { uniqueId } from "lodash";
import { CharacterNames, ICharacter, IStoneObject, IUser } from "./interfaces";

import artemisAvatar from './images/nft-one.png';
import herculesAvatar from './images/nft-two.png';
import chimeraAvatar from "./images/nft-three.png";
import medusaAvatar from "./images/nft-four.png";
import zeusAvatar from "./images/nft-five.png";
import hermesAvatar from './images/nft-six.png';

import achillesAvatar from "./images/achilles.png";
import appolloAvatar from "./images/apolo.png";
import titanAvatar from "./images/titan.png";
import chironAvatar from "./images/chiron.png";
import minotaurAvatar from "./images/minotaur.png";
import atlasAvatar from "./images/atlas.png";
import cyclopAvatar from "./images/cyclop.png";
import pegasusAvatar from "./images/pegasus.png";
import snakeAvatar from "./images/snake.png";
import snakeArmyAvatar from "./images/snake-army.png";
import aresAvatar from "./images/ares.png";
import cerberusAvatar from "./images/cerberus.png";
import chronosAvatar from "./images/chronos.png";
import poseidonAvatar from "./images/poseidon.png";
import furies from "./images/furies.png";
import furiesArmy from "./images/furies-army.png";
import hades from "./images/hades.png";
import sonsOfEgyptos from "./images/sons-of-egyptos.png";
import danaids from "./images/danaids.png";
import hermes from "./images/hermes.png";
import cannon from "./images/cannon.png";
import spark from "./images/spark.png";
import arretDuTemps from "./images/arret-du-temps.png";
import swap from "./images/swap.png";
import angerOfZeus from "./images/anger-of-zeus.png";
import blessing from "./images/blessing.png";
import wave from "./images/wave.png";

export const createStoneObject = (
  numberOfStones: number,
  end: number
): IStoneObject => {
  return {
    numberOfStones,
    id: uniqueId(),
    isClaimed: false,
    isCreated: false,
    end,
  };
};

export const countChildrenHeight = (children: HTMLCollection) => {
  return Array.from(children || []).reduce((acc, child) => {
    acc += child?.clientHeight;

    return acc;
  }, 0);
};

export const createUserObject = (): IUser => {
  return {
    id: uniqueId(),
    olymp: 0,
    paid: false,
    registered: false,
    inGameBalance: 0,
    stones: 0,
    powder: 0,
    powderToCreateStone: 100,
    approvedBusdChests: false,
    approvedBusdMarketplace: false,
    approvedEvolve: false,
    approvedBusdOldMarketplace: false,
    approvedNewEvolve: false,
    approvedPowder: false,
    vestingBalance: 0,
    stakingBalance: 0,
    claimingAvailable: new Date(),
    withdrawAmount: 0,
    logged: false,
    approvedOlympBridge: false,
    approvedMigratedCharactersMarketplace: false,
    approvedCharactersMigrate: false,
    approvedStarter: false,
    approvedNewCharactersMigrate: false,
    purchasedStarter: false,
    claimedStarter: false,
    approvedMigratedCharactersBridge: false,
    approvedChestsMarketplace: false,
    approvedNewChestsMarketplace: false,
    approvedStonesMarketplace: false,
    bUSD: 0,
  };
};

export const formatValue = (value: number, fractionDigits = 2) =>
  new Intl.NumberFormat("en-US", {
    maximumFractionDigits: fractionDigits,
  }).format(value);

export const getCharacterAvatar = (characterType: string) => {
  switch (characterType) {
    case CharacterNames.Achilles:
      return achillesAvatar;
    case CharacterNames.Apollo:
      return appolloAvatar;
    case CharacterNames.Medusa:
      return medusaAvatar;
    case CharacterNames.Chimera:
      return chimeraAvatar;
    case CharacterNames.Titan:
      return titanAvatar;
    case CharacterNames.Zeus:
      return zeusAvatar;
    case CharacterNames.Atlas:
      return atlasAvatar;
    case CharacterNames.Minotaur:
      return minotaurAvatar;
    case CharacterNames.Chiron:
      return chironAvatar;
    case CharacterNames.Hermes:
      return hermesAvatar;
    case CharacterNames.Cyclop:
      return cyclopAvatar;
    case CharacterNames.Pegasus:
      return pegasusAvatar;
    case CharacterNames.Snake:
      return snakeAvatar;
    case CharacterNames.SnakeArmy:
      return snakeArmyAvatar;
    case CharacterNames.Ares:
      return aresAvatar;
    case CharacterNames.Cerberus:
      return cerberusAvatar;
    case CharacterNames.Chronos:
      return chronosAvatar;
    case CharacterNames.Poseidon:
      return poseidonAvatar;
    case CharacterNames.Artemis:
      return artemisAvatar;
    case CharacterNames.Hercules:
      return herculesAvatar;
    case CharacterNames.Furies:
      return furies;
    case CharacterNames.FuriesArmy:
      return furiesArmy;
    case CharacterNames.Hades:
      return hades;
    case CharacterNames.SonsOfEgyptos:
      return sonsOfEgyptos;
    case CharacterNames.Danaids:
      return danaids;
    case CharacterNames.Hermes:
      return hermes;
    case CharacterNames.Cannon:
      return cannon;
    case CharacterNames.Spark:
      return spark;
    case CharacterNames.ArretDuTemps:
      return arretDuTemps;
    case CharacterNames.Swap:
      return swap;
    case CharacterNames.AngerOfZeus:
      return angerOfZeus;
    case CharacterNames.Blessing:
      return blessing;
    case CharacterNames.Wave:
      return wave;
  }
};

export const getCardBackground = (rarity: string) => {
  switch (rarity) {
    case 'Diamond':
      return 'purple-gradient-img'
      case 'Gold':
        return 'yellow-gradient-img'
      default:
        return 'purple-green-gradient-img'
  }
}

export const createCharactersObject = (): ICharacter[] => {
  const characters = [];
  return characters;
};
