import React, { useMemo, useState } from 'react'
import { Button, Col, Collapse, Form, Pagination, Row, Tab } from 'react-bootstrap'
import Doubleslider from 'src/Doubleslider';
import { useMarketplaceContext } from 'src/context/MarketplaceContext';
import Sortby from '../Sortby';
import MarketplaceItem from './Item';
import { IMarketItem } from 'src/interfaces';

interface Props {
  openedItem: IMarketItem | null
  setOpenedItem: (item: IMarketItem | null) => void
}
const AllItems: React.FC<Props> = ({ openedItem, setOpenedItem }) => {
  const { marketItems: allItems, searchTerm, setSearchTerm } = useMarketplaceContext()

  const [filteropen, setFiletrOpen] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(1000000)

  const [minLevel, setMinLevel] = useState(0)
  const [maxLevel, setMaxLevel] = useState(100)

  const [category, setCategory] = useState<'ALL' | 'CHARACTERS' | 'BOXES'>('ALL')
  const [rarity, setRarity] = useState<'ALL' | 'COMMON' | 'RARE' | 'EPIC'>('ALL')

  const marketItems = useMemo(() => {
    let items = allItems
    if (category === 'BOXES') {
      items = items.filter(item => item.category === 'chests')
    } else if (category === 'CHARACTERS') {
      items = items.filter(item => item.category === 'characters')
    }

    if (rarity === 'COMMON') {
      items = items.filter(item => item.rarity === 'common' || item.rarity === 'normal')
    } else if (rarity === 'RARE') {
      items = items.filter(item => item.rarity === 'rare')
    } else if (rarity === 'EPIC') {
      items = items.filter(item => item.rarity === 'epic')
    }

    if (minPrice !== 0) {
      items = items.filter(item => item.price >= minPrice)
    } else if (maxPrice !== 1000000) {
      items = items.filter(item => item.price <= maxPrice)
    }

    if (minLevel !== 0) {
      items = items.filter(item => item.level >= minLevel)
    } else if (maxLevel !== 100) {
      items = items.filter(item => item.level <= maxLevel)
    }

    if (searchTerm !== '') {
      items = items.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    }
    items = items.filter(item => item.price > 0)
    return items
  }, [allItems, searchTerm, minPrice, maxPrice, minLevel, maxLevel, category, rarity])

  const pageLength = 36
  const totalPages = useMemo(() => {
    const totalPages = Math.ceil(marketItems.length / pageLength);
    return totalPages
  }, [marketItems])

  const paginatedItems = useMemo(() => {
    const start = activePage * pageLength
    const end = start + pageLength
    return marketItems.slice(start, end)
  }, [marketItems, activePage])
  const handleClearFilter = () => {
    setCategory('ALL')
    setSearchTerm('')
    setRarity('ALL')
    setMinPrice(0)
    setMaxPrice(1000000)
    setFiletrOpen(false)
    setMinLevel(0)
    setMaxLevel(100)
  };

  return (
    <Tab.Pane eventKey="first" className="marketplace-tab-content">
      <div className="filter mt-4">
        <div className={filteropen ? 'open-filter-box' : 'box'}>
          <Row className="py-3 px-3 px-xl-5 px-lg-4 px-md-4">
            <Col xl={6} lg={4} md={4} xs={12} className="my-auto">
              <h1 className="main-heading color-purple mb-0 text-capitalize">Filters</h1>
            </Col>
            <Col xl={6} lg={8} md={8} xs={12} className="text-end my-auto filter-btn-col">
              <Button
                onClick={() => setFiletrOpen(!filteropen)}
                aria-controls="example-collapse-text"
                aria-expanded={filteropen}
                className="purple-btn border-0 filter-btn"
              >
                {filteropen ? 'Hide Filters' : 'Show Filters'}
              </Button>
              <Button className="white-bordered-btn filter-btn" style={{ display: filteropen ? 'inline-block' : 'none' }} onClick={handleClearFilter}>
                Clear all
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Collapse in={filteropen}>
                <div id="filter-inner" className={filteropen ? 'show-filter-box' : ''}>
                  <img src="/images/line-divider.png" className="w-100 mobile-display pt-3 pb-3" />

                  <Row className="filter-item mt-0">
                    <Col xl={1} lg={2} md={2} xs={12} className="my-ato">
                      <p className="filter-title mb-0">Item</p>
                    </Col>
                    <Col xl={11} lg={10} md={10} xs={12}>
                      <div className="filter-btn ps-0 ps-xl-3 ps-lg-3 ps-md-3">
                        <Button className={`all-item ${category === 'ALL' ? 'active' : ''}`} onClick={() => setCategory('ALL')}>
                          All Items
                        </Button>
                        <Button className={`character ${category === 'CHARACTERS' ? 'active' : ''}`} onClick={() => setCategory('CHARACTERS')}>
                          Characters
                        </Button>
                        <Button className={`boxes ${category === 'BOXES' ? 'active' : ''}`} onClick={() => setCategory('BOXES')}>
                          Boxes
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row className="filter-item">
                    <Col xl={1} lg={2} md={2} xs={12} className="my-auto">
                      <p className="filter-title mb-0">Rarity</p>
                    </Col>
                    <Col xl={11} lg={10} md={10} xs={12}>
                      <div className="filter-btn ps-0 ps-xl-3 ps-lg-3 ps-md-3">
                        <Button className={`all-rarity ${rarity === 'ALL' ? 'active' : ''}`} onClick={() => setRarity('ALL')}>
                          All Rarities
                        </Button>
                        <Button className={`common ${rarity === 'COMMON' ? 'active' : ''}`} onClick={() => setRarity('COMMON')}>
                          Common
                        </Button>
                        <Button className={`rare ${rarity === 'RARE' ? 'active' : ''}`} onClick={() => setRarity('RARE')}>
                          Rare
                        </Button>
                        <Button className={`epic ${rarity === 'EPIC' ? 'active' : ''}`} onClick={() => setRarity('EPIC')}>
                          Epic
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row className="filter-item ">
                    <Col xl={1} lg={2} md={2} xs={12} className="my-auto">
                      <p className="filter-title mb-0">Level</p>
                    </Col>
                    <Col xl={11} lg={10} md={10} xs={12}>
                      <Row>
                        <Col xl={5} lg={5} md={6} xs={12} className="mt-2 mt-xl-4 mt-lg-3 mt-md-3 pe-3 pe-xl-5 pe-lg-5 pe-md-5">
                          <div className=" ps-0 ps-xl-3 ps-lg-3 ps-md-3">
                            <Doubleslider min={0} max={100} values={[minLevel, maxLevel]} step={1} onChange={(value) => {
                              setMinLevel(value[0])
                              setMaxLevel(value[1])
                            }} />
                          </div>
                        </Col>
                        <Col xl={7} lg={7} md={6} xs={12} className="my-auto ps-3 ps-xl-4 ps-lg-4 ps-md-4">
                          <div className="filter-price-main">
                            <p className="filter-title my-auto">Price</p>
                            <div>
                              <Form>
                                <div className="price-filter-form">
                                  <Form.Group className="" controlId="formfrom">
                                    <Form.Control type="number" placeholder="From" className="price-filetr-input" value={minPrice} onChange={e => setMinPrice(parseFloat(e.target.value))} />
                                  </Form.Group>
                                  <Form.Group className="" controlId="formto">
                                    <Form.Control type="number" placeholder="To" className="price-filetr-input" value={maxPrice} onChange={e => setMaxPrice(parseFloat(e.target.value))} />
                                  </Form.Group>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </Col>
          </Row>
        </div>
      </div>

      <Row className="mt-4">
        <Col xl={7} lg={6} md={6} className="d-none d-xl-block d-lg-block d-md-block nft-wallet-main-title">
          <h1 className="main-heading color-purple text-capitalize main-heading-space-left">{marketItems.length} items</h1>
        </Col>

        <Col xl={5} lg={6} md={6} className="position-relative  sort-col mt-4 mt-xl-auto mt-lg-auto mt-md-auto nft-wallet-sort-main text-end my-auto">
          <Sortby />
        </Col>
      </Row>

      <div className="box margin-top-box p-3  p-xl-4  pt-xl-3 nft-tab-content marketplace-first-tab-content">
        <div className="">
          <Row className="">
            {
              paginatedItems.map(item => (
                <MarketplaceItem onClick={() => setOpenedItem(item)} item={item} key={item.id} />
              ))
            }
          </Row>
        </div>
      </div>

      <div className="pagination justify-content-end mt-4">
        <Pagination>
          <Pagination.Prev onClick={() => setActivePage(activePage === 0 ? 0 : activePage - 1)} />
          {
            Array(totalPages).fill(0).map((_, i) => (
              <Pagination.Item key={i} active={i === activePage} onClick={() => setActivePage(i)}>
                {i + 1}
              </Pagination.Item>
            ))
          }
          <Pagination.Next onClick={() => setActivePage(activePage === totalPages - 1 ? activePage : activePage + 1)} />
        </Pagination>
      </div>
    </Tab.Pane>

  )
}

export default AllItems