import { IChest, IOption } from "./interfaces";
import commonChest from "./images/chests/chest1.png";
import uncommonChest from "./images/chests/chest2.png";
import rareChest from "./images/chests/chest3.png";
import legendaryChest from "./images/chests/chest4.png";
import bonusChest from "./images/chests/chest5.png";
import atlasChest from "./images/chests/chest6.png";
import commonIcon from "./images/common-i.svg";
import uncommonIcon from "./images/uncommon-i.svg";
import atlasIcon from "./images/atlas-i.svg";
import bonusIcon from "./images/bonus-i.svg";
import rareIcon from "./images/rare-i.svg";
import legendaryIcon from "./images/legendary-i.svg";
import commonOpenImage from "./images/boxes/common-open.png";
import uncommonOpenImage from "./images/boxes/uncommon-open.png";
import rareOpenImage from "./images/boxes/rare-open.png";
import legendaryOpenImage from "./images/boxes/legendary-open.png";
import atlasOpenImage from "./images/boxes/atlas-open.png";
import bonusOpenImage from "./images/boxes/bonus-open.png";
import boxCommonImage from "./images/boxes/box-opened-common.png";
import boxUncommonImage from "./images/boxes/box-opened-uncommon.png";
import boxRareImage from "./images/boxes/box-opened-rare.png";
import boxLegendaryImage from "./images/boxes/box-opened-legendary.png";
import boxBonusImage from "./images/boxes/box-opened-bonus.png";
import boxAtlasImage from "./images/boxes/box-opened-atlas.png";
import commonAvatars from "./images/avatars/avatars-common.png";
import uncommonAvatars from "./images/avatars/avatars-uncommon.png";
import rareAvatars from "./images/avatars/avatars-rare.png";
import legendaryAvatars from "./images/avatars/avatars-legendary.png";
import atlasAvatars from "./images/avatars/avatars-atlas.png";
import bonusAvatars from "./images/avatars/avatars-bonus.png";

export enum EChestType {
  COMMON = "common",
  UNCOMMON = "uncommon",
  RARE = "rare",
  LEGENDARY = "legendary",
  ATLAS = "atlas",
  BONUS = 'bonus'
}

export enum ESortOption {
  LOWEST_PRICE = "lowest_price",
  HIGHEST_PRICE = "highest_price",
}

export const priceOptions: IOption[] = [
  {
    label: "Lowest price",
    value: ESortOption.LOWEST_PRICE,
  },
  {
    label: "Highest price",
    value: ESortOption.HIGHEST_PRICE,
  },
];


export const chestType: IChest[] = [
  {
    id: "Common",
    name: "Common Chest",
    image: commonChest,
    class: "common",
    icon: commonIcon,
    openChestImage: commonOpenImage,
    price: 30,
    collection: 1,
    category: 'chests',
    level: 0,
    quantity: 0,
    rarity: 0,
    boxInfo: {
      boxImage: boxCommonImage,
      charactersImage: commonAvatars,
      characterChance: 50,
      olympChance: 30,
      olymp: "200-800",
      stoneChance: 10,
      powderChance: 100,
      powder: "10-50",
      goldCharacter: 0,
      diamondCharacter: 0,
    },
  },
  {
    id: "Uncommon",
    name: "Uncommon Chest",
    image: uncommonChest,
    class: "uncommon",
    icon: uncommonIcon,
    openChestImage: uncommonOpenImage,
    price: 60,
    quantity: 0,
    collection: 1,
    category: 'chests',
    rarity: 1,
    level: 0,
    boxInfo: {
      boxImage: boxUncommonImage,
      charactersImage: uncommonAvatars,
      characterChance: 100,
      olympChance: 40,
      olymp: "400-1600",
      stoneChance: 15,
      powderChance: 100,
      powder: "20-100",
      goldCharacter: 20,
      diamondCharacter: 0,
    },
  },
  {
    id: "Rare",
    name: "Rare Chest",
    image: rareChest,
    class: "rare",
    icon: rareIcon,
    openChestImage: rareOpenImage,
    price: 100,
    quantity: 0,
    rarity: 2,
    collection: 1,
    category: 'chests',
    level: 0,
    boxInfo: {
      boxImage: boxRareImage,
      charactersImage: rareAvatars,
      characterChance: 100,
      olympChance: 50,
      olymp: "800-3200",
      stoneChance: 20,
      powderChance: 100,
      powder: "40-200",
      goldCharacter: 50,
      diamondCharacter: 0,
    },
  },
  {
    id: "Legendary",
    name: "Legendary Chest",
    image: legendaryChest,
    class: "legendary",
    icon: legendaryIcon,
    openChestImage: legendaryOpenImage,
    price: 140,
    collection: 1,
    category: 'chests',
    level: 0,
    rarity: 3,
    quantity: 0,
    boxInfo: {
      boxImage: boxLegendaryImage,
      charactersImage: legendaryAvatars,
      characterChance: 100,
      olympChance: 60,
      olymp: "1600-6400",
      stoneChance: 50,
      powderChance: 100,
      powder: "160-400",
      goldCharacter: 60,
      diamondCharacter: 20,
    },
  },
  {
    id: "Atlas",
    name: "Atlas Chest",
    image: atlasChest,
    class: "atlas",
    icon: atlasIcon,
    openChestImage: atlasOpenImage,
    price: 140,
    collection: 2,
    category: 'chests',
    level: 0,
    rarity: 4,
    quantity: 0,
    boxInfo: {
      boxImage: boxAtlasImage,
      charactersImage: atlasAvatars,
      characterChance: 100,
      olympChance: 60,
      olymp: "1600-6400",
      stoneChance: 50,
      powderChance: 100,
      powder: "160-400",
      goldCharacter: 60,
      diamondCharacter: 20,
    },
  },
  {
    id: "Bonus",
    name: "Bonus Chest",
    image: bonusChest,
    class: "bonus",
    icon: bonusIcon,
    openChestImage: bonusOpenImage,
    price: 0,
    collection: 2,
    category: 'chests',
    level: 0,
    rarity: 5,
    quantity: 0,
    boxInfo: {
      boxImage: boxBonusImage,
      charactersImage: bonusAvatars,
      characterChance: 100,
      olympChance: 60,
      olymp: "1600-6400",
      stoneChance: 50,
      powderChance: 100,
      powder: "160-400",
      goldCharacter: 60,
      diamondCharacter: 20,
    },
  },
];