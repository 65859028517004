import { getParsedEthersError } from '@enzoferey/ethers-error-parser'
import { ethers } from 'ethers'
import { toast } from 'sonner'
import React, { useCallback } from 'react'
import { Characters, Chests, ERC20, Marketplace } from 'src/abis'
import { useCharactersData } from 'src/context/CharactersContext'
import { useMarketplaceContext } from 'src/context/MarketplaceContext'
import { useUserData } from 'src/context/UserContext'
import { useEthersSigner } from 'src/context/ethers'
import { IMarketItem } from 'src/interfaces'
import { useAccount } from 'wagmi'

interface Props {
  buttonClasses: string
  sellPrice: number
  item: IMarketItem
}
const SellButton: React.FC<Props> = ({ buttonClasses, item, sellPrice }) => {
  const { address } = useAccount()
  const signer = useEthersSigner()
  const [selling, setSelling] = React.useState(false)
  const [approving, setApproving] = React.useState(false)

  const { fetchListings } = useMarketplaceContext()
  const { fetchCharacters } = useCharactersData()
  const { fetchUserData, setCreatedUser, createdUser } = useUserData()

  const reloadData = useCallback(() => {
    fetchUserData(address)
    fetchCharacters(address)
    fetchListings()
  }, [fetchCharacters, fetchListings, fetchUserData, address])

  const hasApprovedForSale = item.category === 'characters' ? createdUser.approvedMigratedCharactersMarketplace
    : item.category === 'chests' ? item.collection === 2 ? createdUser.approvedNewChestsMarketplace : createdUser.approvedChestsMarketplace
      : createdUser.approvedStonesMarketplace

  const handleSell = useCallback(async () => {
    const marketplaceAddress = process.env.REACT_APP_MARKETPLACE_CONTRACT
    const chestRarity = item.rarity === 'normal'
      ? 0 : item.rarity === 'uncommon'
        ? 1 : item.rarity === 'rare'
          ? 2 : item.rarity === 'legendary'
            ? 3 : item.rarity === 'atlas' ? 4 : 5
    const marketplace = new ethers.Contract(marketplaceAddress, Marketplace, signer)
    const price = process.env.REACT_APP_MAIN_CHAIN_ID === '5' ? sellPrice : ethers.utils.parseUnits(sellPrice.toString(), 18)
    if (sellPrice === 0) {
      toast.error('The price cannot be 0')
      return
    }
    setSelling(true)
    try {
      const method = item.category === 'characters'
        ? marketplace.listERC721(process.env.REACT_APP_MIGRATED_CHARACTERS_CONTRACT, item.id.includes('-') ? item.id.split('-')[1] : item.id.split('_')[1], price)
        : item.category === 'chests'
          ? marketplace.listERC1155(item.collection === 1 ? process.env.REACT_APP_CHESTS_CONTRACT
            : process.env.REACT_APP_BONUS_CHESTS_CONTRACT, chestRarity, 1, price)
          : marketplace.listERC20(process.env.REACT_APP_STONES_CONTRACT || '', 1, price)
      await (await method).wait()
      toast.success('Successfully listed item for sale.')
      reloadData()
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while creating the listing. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setSelling(false)
  }, [item, signer, reloadData, sellPrice])

  const handleApprove = useCallback(async () => {
    const marketplaceAddress = process.env.REACT_APP_MARKETPLACE_CONTRACT
    const contractAddress = item.category === 'characters' ? process.env.REACT_APP_MIGRATED_CHARACTERS_CONTRACT
    : item.category === 'chests' ? item.collection === 2 ? process.env.REACT_APP_BONUS_CHESTS_CONTRACT : process.env.REACT_APP_CHESTS_CONTRACT
    : process.env.REACT_APP_STONES_CONTRACT
    const abi = item.category === 'characters' ? Characters : item.category === 'chests' ? Chests : ERC20
    const contract = new ethers.Contract(contractAddress, abi, signer)
    setApproving(true)
    try {
      const method = item.category === 'evolving_stones' ? contract.approve(marketplaceAddress, '1000')
        : contract.setApprovalForAll(marketplaceAddress, true)
      await (await method).wait()

      setCreatedUser(createdUser => ({
        ...createdUser,
        approvedMigratedCharactersMarketplace: true,
        approvedChestsMarketplace: item.category === 'chests' && item.collection === 1 ? true : createdUser.approvedChestsMarketplace,
        approvedNewChestsMarketplace: item.category === 'chests' && item.collection === 2 ? true : createdUser.approvedNewChestsMarketplace,
        approvedStonesMarketplace: item.category === 'evolving_stones' ? true : createdUser.approvedStonesMarketplace
      }))
      toast.success('Successfully approved. You can now sale your items.')
    } catch (rawError) {
      const error = getParsedEthersError(rawError as Error);
      console.log(rawError);
      let errorMessage = "An unexpected error has occurred while approving. Please try again";
      errorMessage = error && error.context ? error.context : errorMessage;
      toast.error(errorMessage);
    }
    setApproving(false)
  }, [item, signer, setCreatedUser])

  return (
    <button className={`${buttonClasses} yellow-btn-two  text-white  mt-3 me-0 justify-content-center  mx-auto text-uppercase`} disabled={approving || selling}
      onClick={() => hasApprovedForSale ? handleSell() : handleApprove()}>
      {approving ? 'Approving...' : selling ? 'Selling...' : hasApprovedForSale ? 'Sell' : 'Approve to sale'}
    </button>
  )
}

export default SellButton
