import { toast } from 'sonner';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import axios from 'axios';
import { useAccount } from 'wagmi';
import { useUserData } from 'src/context/UserContext';

interface Props {
  show: boolean;
  close: () => void;
  eurAmount: number;
}
const PurchaseOlympModal: React.FC<Props> = ({ show, close, eurAmount }) => {
  const [number, setNumber] = useState('')
  const [expMonth, setExpMonth] = useState(undefined)
  const [expYear, setExpYear] = useState(undefined)
  const [cvc, setCvc] = useState(undefined)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const { createdUser } = useUserData()

  const { address } = useAccount()

  const loadSdk = () => {
    const sandboxSdk = 'https://api.payment.net/compiled/payment.js'

    const script = document.createElement('script');
    script.src = sandboxSdk
    script.async = true;
    document.body.appendChild(script);
  }

  useEffect(() => {
    loadSdk()
  }, [])

  const handlePurchase = () => {
    if (!createdUser.registered) {
      toast.error('You need to register first. Please on go the "play" page to proceed.')
      return
    }
    setLoading(true)
    const Payment = (window as any).Payment as any;
    Payment.setApiDomain('api.payment.net');
    Payment.setPublishableKey('d13b92d9-cd98-11ec-b529-a4bf012eceae');
    Payment.card.createToken({
      "name": name,
      "email": email,
      "number": number,
      "exp_month": expMonth,
      "exp_year": expYear,
      "cvc": cvc,
      "tokenize": true
    }, async function (error, data) {
      if (error || data.error) {
        console.log(data)
        setLoading(false)
        toast.error(data.error.message);
        return
      } else {
        try {
          await axios.post(`${process.env.REACT_APP_API}/api/purchaseolymp`,
            {
              eurAmount,
              token: data.id,
              address
            }
          )
          toast.success('Olymp purchased successfully!')
          setTimeout(() => {
            window.location.reload()
          }, 2000)
        } catch (rawError) {
          setLoading(false)
          const errorMessage = 'An unexpected error has occurred while purchasing OLYMP.'
          const error = rawError as { response?: { data?: { error?: string } } }
          console.log(rawError)
          toast.error((error.response?.data?.error || errorMessage) + '. Please contact us on Discord.')
        }
      }
    });
  }
  return (
    <>
      <Form className="token-box-form">
        <Form.Group className="mt-3">
          <Form.Control
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className='input'
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            value={name}
            type="text"
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            className='input'
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Control
            value={number}
            step={1}
            type="number"
            onChange={(e) => setNumber(e.target.value)}
            placeholder="Card Number"
            className='input'
          />
        </Form.Group>
        <Row className="mt-3">
          <Col xs="7">
            <Row className="">
              <Col xs="6" className="pe-0">
                <Form.Group>
                  <Form.Control
                    value={expMonth}
                    step={1}
                    type="number"
                    onChange={(e) => parseInt(e.target.value) > 12 ? {} : setExpMonth(e.target.value)}
                    placeholder="Exp. Month"
                    className='input'
                  />
                </Form.Group>
              </Col>
              <Col xs="6" className="ps-0">
                <Form.Group>
                  <Form.Control
                    value={expYear}
                    step={1}
                    type="number"
                    onChange={(e) => parseInt(e.target.value) > 9999 ? {} : setExpYear(e.target.value)}
                    placeholder="Exp. Year"
                    className='input'
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col xs="5">
            <Form.Group>
              <Form.Control
                value={cvc}
                step={1}
                type="number"
                onChange={(e) => setCvc(parseInt(e.target.value))}
                placeholder="CVC"
                className='input'
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Button className="purple-btn mt-4 token-btn" disabled={loading} onClick={handlePurchase}>
        {loading ? 'Purchasing...' : 'Purchase'}
      </Button>
    </>
  );
}

export default PurchaseOlympModal