import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import Mobilenavigation from "./Mobilenavigation";
import { useDisconnect } from "wagmi";
const Sidebar = ({ children }) => {
  const [Isopen, SetIsOpen] = useState(true);
  const { disconnect } = useDisconnect()
  const toggle = () => SetIsOpen(!Isopen);
  // to add active class
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(
    () => {
      setUrl(location.pathname);
    },
    [location]
  );
  //  end   to add active class
  return (
    <>
      <div className="pb-0  common-main">
        <div className="main-inner p-4">
          <div>
            <div className={Isopen ? "navigation-col" : "navigation-col open"} style={{ width: Isopen ? "" : "7%" }}>
              <div className="desktop-nav">
                <div className={Isopen ? "sidebar-main" : "sidebar-main side-closed"}>
                  <div className="box  pb-4 pt-4">
                    <div className="text-center">
                      <img src="/images/logo.png" className="mb-3 img-fluid sidebar-logo mx-auto" style={{ display: Isopen ? "block" : "none" }} />
                      <img src="/images/collapse-logo.png" className="mb-3 mx-auto" style={{ display: Isopen ? "none" : "block" }} width={"54px"} />
                    </div>
                    <Navbar>
                      <Nav className="w-100 flex-column" as="ul">
                        <Nav.Item as="li">
                          <Nav.Link as={Link} to="/" className={"" + (url === "/" ? " active" : "")}>
                            <img src="/images/menu-icon1-Home.png" className="" width="29px" height="29px" />
                            <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                              Home
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as={Link} to="/nftwallet" className={"" + (url === "/nftwallet" ? " active" : "")}>
                            <img src="/images/men-icon2- My-NFTs.png" className="" width="29px" height="29px" />
                            <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                              My NFTs
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as={Link} to="/marketplace" className={"" + (url === "/marketplace" ? " active" : "")}>
                            <img src="/images/menu-icon3-Marketplace.png" className="" width="29px" height="29px" />
                            <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                              Marketplace
                            </span>
                          </Nav.Link>
                        </Nav.Item> 
                        <Nav.Item as="li">
                          <Nav.Link as={Link} to="/play" className={"" + (url === "/play" ? " active" : "")}>
                            <img src="/images/menu-icon4-Play.png" className="" width="29px" height="29px" />
                            <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                              Play
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item as="li">
                          <Nav.Link as={Link} to="/renting" className={"" + (url === "/renting" ? " active" : "")}>
                            <img src="/images/menu-icon5-NFT Renting.png" className="" width="29px" height="29px" />
                            <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                              NFT Renting
                            </span>
                          </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item as="li">
                          <Nav.Link as={Link} to="/token" className={"" + (url === "/token" ? " active" : "")}>
                            <img src="/images/menuicon6-My-Tokens.png" className="" width="29px" height="29px" />
                            <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                              My Tokens
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link as={Link} to="/vote" className={"" + (url === "/vote" ? " active" : "")}>
                            <img src="/images/menu-icon7-Vote.png" className="" width="29px" height="29px" />
                            <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                              Vote
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item as="li">
                          <Nav.Link as={Link} to="/staking" className={"" + (url === "/staking" ? " active" : "")}>
                            <img src="/images/menu-icon8-Staking.png" className="" width="29px" height="29px" />
                            <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                              Staking
                            </span>
                          </Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                    </Navbar>
                    <Outlet />
                  </div>

                  <div className="box mt-4">
                    <div className="sidebar-social-div" style={{ display: Isopen ? "flex" : "grid" }}>
                      <a href="https://discord.com/invite/olympusgame" target="_blank" rel="noreferrer">
                        <img src="/images/discord-icon.svg" width="30px" height="30px" />
                      </a>
                      <a href="https://t.me/olympusgame" target="_blank" rel="noreferrer">
                        <img src="/images/telegram-icon.svg" width="30px" height="30px" />
                      </a>
                      <a href="https://twitter.com/OlympusGameP2E" target="_blank" rel="noreferrer">
                        <img src="/images/twitter-icon.svg" width="30px" height="30px" />
                      </a>
                    </div>
                  </div>
                  <div className="box mt-4">
                    <Nav.Link href="/" onClick={() => disconnect()}>
                      <img src="/images/menu-icon-disconnect.png" className=" pt-2 pb-2" width="29px" />{" "}
                      <span style={{ display: Isopen ? "inline-block" : "none" }} className="ps-3">
                        Disconnect
                      </span>
                    </Nav.Link>
                  </div>
                </div>
              </div>

              <div className=" mobile-nav">
                <Mobilenavigation />
              </div>
              <img src="/images/toggle-nav-icon.png" className="nav-toggle-img" onClick={toggle} style={{ transform: Isopen ? "" : "rotate(60deg)" }} />
            </div>
          </div>
          <div className={Isopen ? "content-col" : "content-col open"} style={{ marginLeft: Isopen ? "" : "7%", width: Isopen ? "" : "93%" }}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
